import { Profile } from "../../../domain/account/type/profile";

export class ProfileAdapter {
  static toOutputDTO(response: any): Profile {
    const output_dto: Profile = {
      profile: {
        name: response.vendor_name,
        email: response.email,
        phone: response.phone,
      },
      owner: {
        department: response.manager_department,
        position: response.manager_position,
        name: response.manager_name,
      },
      address: {
        address: response.address,
        city: response.city,
        prefecture: response.prefecture,
        postcode: response.postcode,
        country: response.country,
      },
      logo: { url: response.image_url },
    };
    return output_dto;
  }
}
