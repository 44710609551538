import { UploadFileRequest, StoreFileRequest, FileInfo } from "./type/store";
import { APIStatus } from "../_shared/type/status";
import { StoreInfo } from "./type/info";
import { DeleteIDCodeRequest, EditIDCodeRequest, IDCode } from "./type/id-code";
import {
  DeleteGroupRequest,
  EditGroupRequest,
  AddGroupRequest,
  Group,
} from "./type/group";
import {
  NodeHistogramRequest,
  EditNodeRequest,
  Histogram,
  Node,
} from "./type/node";

import { IDCodeAdapter } from "../../repository/data-manager/adapter/id-code";
import { StoreAdapter } from "../../repository/data-manager/adapter/store";
import { GroupAdapter } from "../../repository/data-manager/adapter/group";
import { NodeAdapter } from "../../repository/data-manager/adapter/node";

import { IDataManagerRepository } from "./irepo";

export class DataManagerUsecase {
  private repository: IDataManagerRepository;
  private error: (error: any) => void;

  constructor(repository: IDataManagerRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  // INFO
  async fetchInfo(): Promise<StoreInfo> {
    try {
      const response = await this.repository.fetchInfo();
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // ID CODE
  async fetchIDCode(): Promise<IDCode[]> {
    try {
      const data = await this.repository.fetchIDCode();
      const response = IDCodeAdapter.toIDCodeOutputDTO(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async addIDCode(): Promise<APIStatus> {
    try {
      const data = await this.repository.addIDCode();
      const response = IDCodeAdapter.toIDCodeStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editIDCode(request: EditIDCodeRequest): Promise<APIStatus> {
    try {
      const input_dto = IDCodeAdapter.toEditIDCodeInputDTO(request);
      const data = await this.repository.editIDCode(input_dto);
      const response = IDCodeAdapter.toIDCodeStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteIDCode(request: DeleteIDCodeRequest): Promise<APIStatus> {
    try {
      const input_dto = IDCodeAdapter.toDeleteIDCodeInputDTO(request);
      const data = await this.repository.deleteIDCode(input_dto);
      const response = IDCodeAdapter.toIDCodeStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // NODE
  async fetchNode(): Promise<Node[]> {
    try {
      const data = await this.repository.fetchNode();
      const response = NodeAdapter.toNodeOutputDTO(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editNode(request: EditNodeRequest): Promise<APIStatus> {
    try {
      const input_dto = NodeAdapter.toEditNodeInputDTO(request);
      const data = await this.repository.editNode(input_dto);
      const response = NodeAdapter.toNodeStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchNodeHistogram(
    request: NodeHistogramRequest
  ): Promise<Histogram[]> {
    try {
      const response = await this.repository.fetchNodeHistogram(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // GROUP
  async fetchGroup(): Promise<Group[]> {
    try {
      const data = await this.repository.fetchGroup();
      const response = GroupAdapter.toGroupOutputDTO(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async addGroup(request: AddGroupRequest): Promise<APIStatus> {
    try {
      const data = await this.repository.addGroup(request);
      const response = GroupAdapter.toGroupStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editGroup(request: EditGroupRequest): Promise<APIStatus> {
    try {
      const data = await this.repository.editGroup(request);
      const response = GroupAdapter.toGroupStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteGroup(request: DeleteGroupRequest): Promise<APIStatus> {
    try {
      const input_dto = GroupAdapter.toDeleteGroupInputDTO(request);
      const group = await this.repository.deleteGroup(input_dto);
      const response = GroupAdapter.toGroupStatus(group);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // STORAGE
  async checkFile(): Promise<FileInfo[]> {
    try {
      const response = await this.repository.checkFile();
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async uploadFile(request: UploadFileRequest): Promise<APIStatus> {
    try {
      const data = await this.repository.uploadFile(request);
      const response = StoreAdapter.toStoreProcessStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async storeFile(request: StoreFileRequest): Promise<APIStatus> {
    try {
      const data = await this.repository.storeFile(request);
      const response = StoreAdapter.toStoreProcessStatus(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
