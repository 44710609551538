import { apiClient } from "../../../libs/api";

export class GraphGateway {
  // INFO
  async fetchOutcomeNode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/summary`;
    const response = await apiClient.get({ url: url });
    return response as any;
  }
}
