import { MFA } from "../../../domain/auth/type/login-user";

export class MFAAdapter {
  static toSetupMFAOutputDTO(response: any): MFA {
    let expire = new Date();
    expire.setMinutes(expire.getMinutes() + response.ExpiresIn);
    return {
      token: response.AccessToken,
      expire: expire,
    };
  }

  static toVerifyMFAOutputDTO(response: any): MFA {
    let expire = new Date();
    expire.setSeconds(expire.getSeconds() + response.ExpiresIn);
    return {
      token: response.AccessToken,
      expire: expire,
    };
  }
}
