import dataManager from "./data-manager";
import dashboard from "./dashboard";
import account from "./account";
import shared from "./shared";
import graph from "./graph";
import auth from "./auth";

const ja = {
  translation: {
    "btn-regist": "登録",
    "btn-edit": "変更",
    "btn-delete": "削除",
    "btn-back": "戻る",
    "btn-billing": "購入",
    "btn-send": "送信",
    "btn-confirm": "確認",
    "btn-regist2": "申し込む",
    "btn-card": "クレジットカード登録",
    "btn-add": "追加",
    "btn-mail": "メール",
    "btn-plan-cancel": "解約",
    "btn-leave": "退会",
    "btn-ok": "OK",

    "header-corporate": "企業サイト",
    "header-service": "サービス",
    "header-wellness": "ウェルネス",
    "header-dashboard": "ダッシュボード",
    "header-graph": "因果グラフ",
    "header-data": "データ管理",
    "header-account": "アカウント",
    "header-support": "ドキュメント",
    "header-logout": "ログアウト",
    "header-login": "ログイン",

    "support-manual": "マニュアル",
    "support-manual-basic": "マニュアル（因果推論ベーシック・バージョン）",
    "support-manual-advanced": "マニュアル（因果推論アドバンス・バージョン）",
    "support-whitepaper": "ホワイトペーパー",
    "support-whitepaper-basic":
      "ホワイトペーパー（因果推論ベーシック・バージョン）",
    "support-whitepaper-advanced":
      "ホワイトペーパー（因果推論アドバンス・バージョン）",

    "user-registration": "識別コード管理",
    "personal-code": "識別コード",
    "youd-user-id": "youdユーザーID",
    "usage-status": "利用状況",
    "usage-status-unused": "未使用",
    "usage-status-expired": "利用終了",
    "usage-status-in-use": "利用中",
    "usage-status-unregistered": "未登録",

    "app-code-desc-1":
      "「you'd」アプリデータとお手持ちのCSVデータを連携する場合は、こちらで発行した識別コードをユーザーへメール等で配布してください。\n当社側では個人情報を持たないため、識別コードとユーザー情報の管理をお願い致します。",
    "app-code-num": "アプリ連携済コード数",
    "app-code-add": "識別コード発行",
    "app-code-download": "CSVダウンロード",
    "app-code-list-id": "利用ユーザー",
    "app-group": "グループ割り当て",
    "select-all": "全選択",
    "app-status-not-set": "未設定",
    "app-status-unpublished": "未発行",
    "app-code-show-more": "もっと見る",
    "app-category-title": "グループ管理",
    "app-category-desc":
      "グループを追加すると任意の識別コードに割り当てて管理することができます。",

    "regist-vendor-title": "企業情報登録",
    "vendor-name": "企業名",
    "vendor-postcode": "郵便番号",
    "vendor-address": "住所",
    "vendor-department": "部署名",
    "vendor-position": "役職",
    "vendor-manager": "氏名",
    "vendor-phone": "電話番号",
    "vendor-email": "メールアドレス",
    "vendor-logo": "企業ロゴ",
    "vendor-comma": "、",

    "vendor-country": "国名",
    "vendor-prefecture": "都道府県",
    "vendor-city": "市区町村",

    "vendor-plan-1-months": "1ヶ月",
    "vendor-plan-6-months": "6ヶ月",
    "vendor-plan-1-year": "1年",

    "vendor-plan-basic": "ベーシック・バージョン",
    "vendor-plan-basic-desc":
      " ダッシュボード\n 因果探索(3種類)\n 因果推論\n Smallyticsアルゴリズム\n 離散値のみ対応",

    "vendor-plan-advanced": "ベーシック・バージョン",
    "vendor-plan-advanced-desc":
      " ダッシュボード\n 因果探索(3種類)\n 因果推論\n Smallyticsアルゴリズム\n 離散値と連続値の混在利用可\n 層別化解析\n 直接(NDE)・間接効果(NIE)の推定\n 頑健性評価\n 論文検索機能",

    "vendor-plan-code": "データ数課金プラン",
    "vendor-plan-code-desc":
      "基本料金 + データ数(xCausalで発行する識別コード数)による課金\n 5アカウントまで基本サービスに含む",

    "vendor-plan-cap": "容量課金プラン",
    "vendor-plan-cap-desc":
      "基本料金(1GBまで) ＋ 追加データ容量(1GB単位)による課金\n 5アカウントまで基本サービスに含む\n リファレンスへご協力いただける場合、初年度基本料金を15％ディスカウントン",

    "vendor-plan-reference":
      "※ リファレンスへご協力いただける場合、個別にお問い合わせください",

    "vendor-plan-5-accounts": "・5アカウント分(管理者含む)",
    "vendor-plan-personal-codes": "・ユーザー連携用識別コード提供",
    "vendor-plan-healthcare-dashboard": "・ヘルスケアダッシュボード",
    "vendor-plan-user-health-dashboard": "・ユーザー体調ダッシュボード(個人)",
    "vendor-plan-data-upload": "・自社データアップロード連携",
    "vendor-plan-causal-basic": "・因果推論ベーシックバージョン",
    "vendor-plan-bayesian-version": " ベイジアンネットワーク版",
    "vendor-plan-10-nodes-included": " (10ノードまで利用可能) ",
    "vendor-plan-1-month-free": " １ヶ月間無料トライアル(5ノード)",
    "vendor-plan-6-month-plan": "6ヶ月プラン",
    "vendor-plan-1-year-plan": "1年プラン",
    "vendor-plan-basic-charge": "基本料金：",
    "vendor-plan-additional-fee": "追加料金：",
    "vendor-plan-150000": "150,000円",
    "vendor-plan-240000": "240,000円",
    "vendor-plan-375000": "375,000円",
    "vendor-plan-600000": "600,000円",
    "vendor-plan-per-user": "アプリユーザー毎：",
    "vendor-plan-150-month": "150円/月",
    "vendor-plan-200-month": "200円/月",
    "vendor-plan-300-month": "300円/月",
    "vendor-plan-400-month": "400円/月",
    "vendor-plan-future-release": "今後リリース予定",
    "vendor-plan-add-accounts": "アカウント追加",
    "vendor-plan-5-set": " (5アカウント)",
    "vendor-plan-sets-of-5":
      "追加アカウントは5アカウント毎に購入いただけます。",
    "vendor-plan-quantity": "数量",
    "vendor-plan-confirm-changes": "変更する",
    "vendor-plan-contract-details-title": "契約内容確認",
    "vendor-plan-contract-service": "契約サービス",
    "vendor-plan-contract-service-detail":
      "料金は契約期間分の基本料金(一括払い)とアプリ連携ユーザーの利用者数による月額料金(月額払い)で請求されます。",
    "vendor-plan-service-menu-basic": "サービスメニュー 基本料金",
    "vendor-plan-contract-period": "契約期間",
    "vendor-plan-basic-charge-2": "基本利用料金",
    "vendor-plan-no-change": " （変更なし）",
    "vendor-plan-total": "合計 (税込)",
    "vendor-plan-monthly-code-fee": "データ数に基づく月額料金",
    "vendor-plan-monthly-cap-fee": "データ容量に基づく月額料金",
    "vendor-plan-monthly-usage-fee": "月額利用料金",
    "vendor-plan-monthly-code-fee-per": "1データあたりの月額 (税込)",
    "vendor-plan-monthly-cap-fee-per": "1GBあたりの月額 (税込)",

    "vendor-plan-service-changed": "サービス変更",
    "vendor-plan-service-change-1": "契約内容の変更を行いました。",
    "vendor-plan-service-change-2": "反映までしばらくお待ちください。",

    "plan-desc-health-5": "・you'dアプリのユーザー統計データ利用可 (個別見積)",
    "plan-desc-opt-basic-1": "・Smallyticsによるノードの推奨",
    "plan-desc-opt-basic-2": "・ベイジアンネットワーク",
    "plan-desc-opt-basic-3": "・離散化により利用(離散値のみ) ",
    "plan-desc-opt-advance-1": "・Smallyticsによるノードの推奨",
    "plan-desc-opt-advance-2": "・ベイジアンネットワーク/SCM",
    "plan-desc-opt-advance-3": "・離散値と連続値を混在利用可",
    "plan-desc-opt-advance-4": "・自然直接効果(NDE)自然間接効果(NIE)",
    "plan-desc-opt-advance-5": "・頑健性評価",
    "plan-desc-opt-advance-6": "・論文検索機能",

    "plan-period-1-month": "月額プラン",
    "plan-period-6-month": "半年プラン",
    "plan-period-1-year": "年間プラン",
    "plan-price-table-1-1": "ベーシック・バージョン(月額)(税込)",
    "plan-price-table-1-1-2": "５アカウント分含む",
    "plan-price-table-1-2": "アドバンス・バージョン(月額)(税込)",
    "plan-price-table-2-1": "基本料金",
    "plan-price-table-2-2": "データ数課金分",
    "plan-price-table-2-3": "追加アカウント",

    "plan-price-table-cap-1-1-2": "容量1GB・５アカウント分含む",
    "plan-price-table-cap-2-2": "容量課金分",

    "plan-youd-option": "you’d連携\nオプション",
    "youd-content-title-1": "「",
    "youd-content-title-2": "you'd™︎",
    "youd-content-title-3": "」連携解析サービス",
    "youd-content-guide-1-1": "",
    "youd-content-guide-1-2": "you'd™︎(ユード)",
    "youd-content-guide-1-3":
      "は、ウェアラブル端末やスマートフォンに蓄積されたライフログ、ユーザー自身が入力した体調や習慣の記録、さらに天気や気圧などの環境データを掛け合わせて解析を行うコンディショニングAIアプリです。",
    "youd-content-guide-2-1": "",
    "youd-content-guide-2-2": "you'd™︎(ユード)",
    "youd-content-guide-2-3":
      "と連携し、契約者様の企業が保有する独自のデータも組み合わせることで、より高度でカスタマイズされたデータ解析が可能です。さらに精度の高い解析結果とインサイトを導き出し、ビジネスの意思決定や戦略策定に役立てていただけます。",
    "youd-contenxt-popup":
      "you'd連携オプションをご利用の場合には、ご契約時に事前のお申し込みが必要です。you'dアプリは無料でご利用いただけます。なお、契約途中でyou'd連携への切り替えはできかねます。途中切り替えの場合には、切り替え以前のデータを移行できないため、再度初期設定をおこなっていただく必要がございます。",

    "vendor-plan-monthly-code-1": "データ数",
    "vendor-plan-monthly-code-2": "の時の月額換算(税込)",
    "vendor-plan-monthly-cap-1": "データ容量",
    "vendor-plan-monthly-cap-2": "GBの時の月額換算(税込)",

    "regist-vendor-plan-custom": "上記以外のお見積・カスタム対応",
    "vendor-plan-custom-data": "データ対応",
    "plan-desc-custom-data-1": "・1,000データ数/100項目以上のデータ数課金",
    "plan-desc-custom-data-2": "・10GBを超えるデータ容量課金",
    "plan-desc-custom-data-3": "・ヴェルト社の保有するデータ利用",
    "plan-desc-custom-data-4": "・サード・パーティーのデータ購入利用",

    "vendor-plan-custom-individual": "カスタム開発・プロフェッショナルサービス",
    "plan-desc-custom-individual-1": "・画面・機能のカスタム開発",
    "plan-desc-custom-individual-2": "・専用AI環境構築による利用",
    "plan-desc-custom-individual-3": "・ユーザー企業のシステムとの連携/統合",
    "plan-desc-custom-individual-4": "・導入/運用コンサルティング",
    "plan-desc-custom-individual-5": "・当社による解析・レポーティング",

    "vendor-plan-custom-partnership": "パートナーシップ",
    "plan-desc-custom-partnership-1": "・コンサルティング型パートナーシップ",
    "plan-desc-custom-partnership-2": "・付加価値再販型パートナーシップ",
    "plan-desc-custom-partnership-3": "・Smallyticsアルゴリズムの供給",
    "plan-desc-custom-partnership-4": "・you’dアプリのOEM供給",
    "regist-vendor-inquiry": "お問い合わせ",

    "regist-vendor-complete-title": "企業情報登録完了",
    "regist-vendor-complete-message":
      "決済処理を行っています。\n反映までしばらくお待ちください。",
    "regist-vendor-inspection-title": "企業情報確認中",
    "regist-vendor-inspection-message":
      "確認を行っています。\nしばらくお待ちください",
    "regist-vendor-ready-title": "企業情報確認完了",
    "regist-vendor-ready-message":
      "企業情報の確認が完了しました。支払情報の登録を行ってください。",

    "regist-vendor-select-service": "利用サービスの選択",
    "regist-vendor-plan-desc-1":
      "xCausal(クロス・コーザル)では「ベーシック・バージョン」と「アドバンス・バージョン」の2つのメニューをご用意しております。",
    "regist-vendor-plan-desc-2":
      "ベーシック・バージョン\nデータを可視化するダッシュボードと、因果探索(3種類)と因果推論を行うことが出来ます。\n因果推論時に、Smallyticsという因果性を考慮した変数推奨アルゴリズムを利用可能です。\n離散値のみで計算処理に対応しています。",
    "regist-vendor-plan-desc-3":
      "アドバンス・バージョン\nデータを可視化するダッシュボードと、因果探索(3種類)と因果推論を行うことが出来ます。\n因果推論時に、Smallyticsという因果性を考慮した変数推奨アルゴリズムを利用可能です。\n離散値に加えて連続値での計算処理に対応しています。\nまた、上記に加え「層別化解析」「直接・間接効果の推定」といった詳細な解析機能と「頑健性評価」「論文検索機能」といった信頼性確保の機能が利用可能です。\n今後も新機能を追加予定です。",
    "regist-vendor-plan-desc-4":
      "課金体系\n料金プランは「データ数課金版」と「容量単価版」という2つのプランからお選びいただけます。",
    "regist-vendor-plan-desc-5":
      "それぞれのプランにおける契約期間は3つの期間(1ヶ月・6ヶ月・１年)からお選びいただけます。\n契約期間がより長いプランほど、月額換算の金額がお安くなります。\n※契約期間内の途中解約はできず、お支払いを継続いただくことが利用条件となります。\nあらかじめご了承ださい。",
    "regist-vendor-plan-desc-6":
      "データ数課金プラン (データ数が小さい場合におすすめ)\n基本料金 + データ数(xCausalで発行する識別コード数)による課金\n5アカウントまで基本サービスに含まれております。\nアカウント数を増やしたい場合には、追加でのご契約が必要です。\n\nデータ数1,000・100項目までの料金体系\n※1 : 1,000名・100項目以上の場合は個別にお問い合わせください。",
    "regist-vendor-plan-desc-7":
      "容量課金プラン(データ数が多い場合におすすめ)\n基本料金(1GBまで) ＋追加データ容量(1GB単位)による課金\n5アカウントまで基本サービスに含まれております。\nアカウント数を増やしたい場合には、追加でのご契約が必要です。",
    "regist-vendor-plan-desc-8":
      "※2 : リファレンスへご協力いただける場合、初年度基本料金を15％ディスカウントします。\n個別にお問い合わせください",

    "regist-vendor-choose": "決定する",
    "regist-vendor-warnings-1":
      "xCausalはお手持ちのデータをアップロードして使えます。",
    "regist-vendor-warnings-2":
      "また、当社のコンディショニングアプリ「you’d」を利用してライフログ取得・連携する事も可能です。",
    "regist-vendor-warnings-3": "その場合の連携フローが下記のようになります。",

    "regist-vendor-alt-1": "個人・医療・クラウドの繋がり",
    "regist-vendor-step": "ステップ",
    "regist-vendor-flow-0":
      "クロス・コーザルサービスをご利用する際には、まずWebで必要事項の登録を行いアカウントを開設します。",
    "regist-vendor-flow-1":
      "クロス・コーザル・ポータルサイト上で発行される識別コードを取得し、自社の個人ユーザー(または患者)へ配布いただく準備を行います。入手した識別コードと、メールアドレス等の自社で持っているユーザー情報を紐付けて管理します。",
    "regist-vendor-flow-2":
      "識別コードを自社の個人ユーザーへメールにて配布します。この際、十分なユーザー数と網羅的にデータを確保することがポイントです。解析対象となるデータ項目全てについて記録があるユーザーが、30名以上いることを推奨しています。",
    "regist-vendor-flow-3":
      "識別コードを受け取ったユーザーは、当社のパーソナルコンディショニングアプリyou’d(またはyou’dのOEMアプリ)を通じて、データのやり取りを行うため、まずアプリをダウンロードいただきます。(「you’d」で検索ください。)",
    "regist-vendor-flow-4":
      "識別コードを受け取ったユーザーは、you'd™アプリ上で表示される識別コード入力画面で識別コードを入力し、自分のヘルスデータやライフログの提供と送り元の企業や医療機関にあるデータとの連携について、アプリ画面上で承諾します。",
    "regist-vendor-flow-5":
      "承諾後、you'd™アプリで取得された個人ユーザーのデータは、クラウドにアップロードされます。",
    "regist-vendor-flow-6":
      "自社にあるデータをクロス・コーザル・ポータルサイトのデータ連携ページからcsv形式でアップロードします。",
    "regist-vendor-flow-7":
      "識別コードで連携します。(個人情報はご提供いただかない仕組みです)データ連携後は、匿名化され統計化された状態でクロス・コーザル・サービスをご利用いただきます。ご利用可能なデータは、ユーザーのスマートフォン上のiOS™のヘルスケアや、Android ™版がリリースされた際はGoogleFitにあるヘルスデータ、および「you'd™」でユーザーが入力した体調、不調症状、習慣に関するデータです。ユーザー数が少ない項目に関しては、人数とデータが十分に揃った段階で表示されます。",
    "regist-vendor-flow-9":
      "自社の個人ユーザーや新規個人ユーザーに向けた商品・サービス開発、オンライン診療、IoTサービスなどへご利用いただけます。",
    "regist-vendor-logo-skip": "後でアップロードする",

    PLAN_ID_PLUGIN_1M: "ウェルネス・プラン (一般企業向け)",
    PLAN_ID_PLUGIN_6M: "ウェルネス・プラン (一般企業向け)",
    PLAN_ID_PLUGIN_1Y: "ウェルネス・プラン (一般企業向け)",
    PLAN_ID_MEDICAL_1M: "メディカルアシスト・プラン (医師・医療機関向け)",
    PLAN_ID_MEDICAL_6M: "メディカルアシスト・プラン (医師・医療機関向け)",
    PLAN_ID_MEDICAL_1Y: "メディカルアシスト・プラン (医師・医療機関向け)",
    PLAN_ID_DE_BASIC_1M: "因果推論ベーシック・バージョン",
    PLAN_ID_DE_BASIC_6M: "因果推論ベーシック・バージョン",
    PLAN_ID_DE_BASIC_1Y: "因果推論ベーシック・バージョン",
    PLAN_ID_DE_ADVANCED_1M: " 因果推論アドバンス・バージョン",
    PLAN_ID_DE_ADVANCED_6M: " 因果推論アドバンス・バージョン",
    PLAN_ID_DE_ADVANCED_1Y: " 因果推論アドバンス・バージョン",

    PLAN_ID_CODE_BASIC_1M: " データ数課金プラン ベーシック・バージョン",
    PLAN_ID_CODE_BASIC_6M: " データ数課金プラン ベーシック・バージョン",
    PLAN_ID_CODE_BASIC_12M: " データ数課金プラン ベーシック・バージョン",
    PLAN_ID_CODE_ADVANCED_1M: " データ数課金プラン アドバンス・バージョン",
    PLAN_ID_CODE_ADVANCED_6M: " データ数課金プラン アドバンス・バージョン",
    PLAN_ID_CODE_ADVANCED_12M: " データ数課金プラン アドバンス・バージョン",
    PLAN_ID_CAP_BASIC_1M: " 容量課金プラン ベーシック・バージョン",
    PLAN_ID_CAP_BASIC_6M: " 容量課金プラン ベーシック・バージョン",
    PLAN_ID_CAP_BASIC_12M: " 容量課金プラン ベーシック・バージョン",
    PLAN_ID_CAP_ADVANCED_1M: " 容量課金プラン アドバンス・バージョン",
    PLAN_ID_CAP_ADVANCED_6M: " 容量課金プラン アドバンス・バージョン",
    PLAN_ID_CAP_ADVANCED_12M: " 容量課金プラン アドバンス・バージョン",

    "category-add-title": "グループ追加",
    "category-add-name": "名称",
    "category-add-desc": "簡易説明",
    "category-add-sended": "グループを追加しました。",

    "corporate-discretization-set": "設定を行う",

    "corporate-health-title": "CSVアップロード",
    "corporate-health-desc":
      "発行した識別コードを記載したCSVを以下の手順でアップロードします。",
    "corporate-health-desc2":
      "　1.「サンプルダウンロード」からダウンロードしたサンプルを参考に、時系列データを記載したCSVを作成\n　2.「CSVチェッカーダウンロード」からチェッカーをダウンロードし、作成したCSVの検査を行い検査済みCSVを出力(※)\n　3. 検査済みCSVを「アップロード」からアップロード\n　4. 必要に応じて「サマリ表示」からアップロードサマリを確認\n\n※ CSVチェッカーはWindowsユーザー専用です。他のOSをご利用の場合は、CSVチェッカーマニュアルを参照し、正しいフォーマットのCSVを作成してください。",
    "corporate-health-desc3-strong": "注意)",
    "corporate-health-desc3":
      "本サービスでの解析を利用するためには、CSVアップロード後、下記の2つの設定が必要です。\n\n　1.【単位・欠損補完・アプリ表示設定】で各データ項目を設定して「設定を更新する」ボタンをクリック\n　2.【因果推論データ設定】で「設定を行う」ボタンをクリックし、各データ項目を設定して「設定を反映する」をボタンをクリック",

    "corporate-health-csv": "サンプル\nダウンロード",
    "corporate-health-csv-1": "CSVチェッカー\nダウンロード",
    "corporate-health-description":
      "説明:健康診断データ連携に関する説明文。\nxxxxxxxx xxxxxxxx xxxx\nxxxxxxx xxxxx",
    "corporate-health-file": "ファイルを選択してください",

    "corporate-health-file-title": "アップロード方法の選択",
    "corporate-health-file-select": "アップロード方法を選択してください",
    "corporate-health-file-new": "新規ファイル/上書き",
    "corporate-health-file-add": "データ追加",
    "corporate-health-upload": "アップロード",
    "corporate-health-summary": "サマリ表示",
    "corporate-health-summary-days": "期間:",
    "corporate-health-summary-users": "人数:",
    "corporate-health-summary-records": "レコード数:",
    "corporate-health-summary-records-dist": "(※1日単位/欠損値補間済)",
    "corporate-health-summary-columns": "項目数:",
    "corporate-health-summary-last-update": "※最終アップロード日時:",

    "corporate-health-show": "1.【単位・欠損補完・アプリ表示設定】",
    "corporate-health-show-key": "項目名",
    "corporate-health-show-none": "表示設定なし",
    "corporate-show-license": "表示項目の設定",
    "corporate-health-show-description-6":
      "アップロードしたデータ項目の単位、単位名、データのタイプ、アプリへの表示を設定します。\n\n※「設定を反映する」ボタンを押し、2.【因果推論データ設定】に進んでください。",

    "set-unit": "単位設定",
    "unit-name": "単位名",
    "decimal-places": "小数点の桁数",
    "data-type": "欠損補完のタイプ",
    "display-in-user-app": "ユーザーアプリ表示",
    "vital-data-type": "線形補完（連続データ等）",
    "event-data-type": "ゼロ補完（イベントデータ等）",
    "update-settings": "設定を反映する",

    "session-error-title": "セッション切れ",
    "session-error-msg":
      "セッションの有効期限が切れました。再認証してください。",

    "error-email-empty": "メールアドレスを入力してください。",
    "error-input-empty": "入力されていない項目があります。",

    "error-empty-columns": "を入力してください。",

    "app-discret-title": "2.【因果推論データ設定】",
    "app-discret-desc-2":
      "最初に離散化レベル値を設定し、手動の場合は各レベルの境界となる値を決定します。\n因果階層設定では、同じ層、数字の大きな層への因果のみを許可します。",
    "app-discret-desc-4":
      "※「設定を行う」ボタンをクリックし、各データ項目を設定して「設定を反映する」をボタンをクリックしてください。",

    "app-discret-update-button": "設定を反映する",
    "app-discret-selection": "選択",
    "app-discret-upload": "データ項目",
    "app-discret-levels": "離散化レベル数",
    "app-discret-range": "離散範囲設定",
    "app-discret-remove-outliers": "外れ値の削除",
    "app-discret-level-settings": "因果階層設定",
    "app-discret-boundary-values": "離散化レベル境界値の設定",
    "app-discret-auto": "自動",
    "app-discret-manual": "手動",
    "app-discret-on": "オン",
    "app-discret-off": "オフ",
    "app-discret-update-display": "表示更新",

    "node-table-ranking-label": "関連順位",
    "node-table-item-name": "項目名",
    "node-table-number-of-people": "人数",
    "node-table-number-of-record": "総レコード数",

    "demo-graph-male": "男性",
    "demo-graph-female": "女性",
    "demo-graph-age-group": "年代",
    "demo-graph-others": "その他",

    "footer-top": "トップ",
    "footer-faq": "FAQ",
    "footer-inquiry": "お問い合わせ",
    "footer-terms": "利用規約",
    "footer-privacy": "プライバシーポリシー",
    "footer-security": "情報セキュリティ方針",

    "trial-popup-1":
      "1ヶ月の無料トライアル期間中は請求が発生することはございません。",
    "trial-popup-2":
      "※無料トライアル終了後、自動更新となりますのでご注意ください。",

    "error-auth": "認証エラーが発生しました。再度ログインしてください。",
    "error-within7days": "現在、7日以内のデータは解析できません。",
    "error-invalidValue": "無効な数値です。",
    "error-exceedMaxValue": "最大値を超えています。",
    "error-noOutcomeNode": "アウトカムノードを選択してください。",
    "error-noRelevanceNode": "関連ノードを選択してください。",
    "error-noData":
      "この条件ではデータがありません。他の条件で試してください。",
    "error-exceedMaxNumberOfNodes1": "最大",
    "error-exceedMaxNumberOfNodes2": "個まで選択できます。",
    "error-api-unknown":
      "サーバーからデータをロード中にエラーが発生しました。 後でもう一度やり直してください。",

    // NEW ERROR
    "error-check-username-and-password":
      "ユーザネームまたはパスワードをもういちど確認してください",
    "communication-error": "通信エラー",
    "error-reenter-code": "コードを再度入力してください",
    "error-enter-code": "コードを入力してください",
    "error-no-service-contract": "サービス契約されていません",
    "error-not-enough-items": "項目が足りません",
    "error-no-data": "データがありません",
    "error-datetime-out-of-range": "範囲外の日時データがあります",
    "error-personal-code-user-not-found":
      "識別コードに紐づくユーザーが見つかりません",
    "upload-error": "アップロードエラー",
    "ten-items-error": "アプリに表示できるのは最大10項目です",
    "error-incorrect-value-entered": "入力された値が正しくありません",
    "error-no-data-uploaded": "アップロードされたデータがありません",
    "error-failed-update-settings": "設定の更新に失敗しました",
    "error-failed-get-data": "データの取得に失敗しました",
    "error-incorrect-value-set": "設定された値が正しくありません",
    "error-check-with-doctor": "医師の確認を行ってください",
    "error-member-info-not-confirmed": "メンバー情報が確認できませんでした。",
    "error-select-plan": "プランを選択してください",
    "error-logo-size-too-large": "企業ロゴのサイズが大きすぎます",
    "error-select-option": "オプションプランを選択してください",
    "error-invalid-additional-account": "追加アカウントが不正です。",

    // COMMON
    "common-confirm": "確定",
    "common-change-name": "名称変更",
    "common-delete": "削除",
    "common-quarter-day": "1/4日",
    "common-one-day": "1日",
    "common-one-week": "1週",
    "common-one-month": "1ヶ月",
    "common-day": "日",
    "common-week": "週",
    "common-month": "月",

    "common-genders-label": "性別",
    "common-male-label": "男性",
    "common-female-label": "女性",
    "common-other-label": "その他",
    "common-age-label": "年代",
    "common-ages-label": "年代",
    "common-address-label": "地域",
    "common-days-label": "日",
    "common-weeks-label": "週",
    "common-months-label": "ヶ月",
    "common-people-label": "人",
    "common-basic-information-label": "基本情報",
    "common-whole-body-label": "全体",
    "common-whole-body-body-label": "体全体",
    "common-condition-label": "体調",
    "common-worrisome-symptoms-label": "気になる不調症状",
    "common-location-label": "部位",
    "common-very-good-label": "とても良い",
    "common-good-label": "良い",
    "common-ok-label": "まあまあ",
    "common-bad-label": "悪い",
    "common-very-bad-label": "とても悪い",

    "common-error-message": "エラーのため表示できません",

    "not-advanced-message":
      "こちらのサービスをご利用になるにはアドバンス・バージョンのご契約が必要です。",
    "not-advanced-message-link": "詳しくはこちらから",
    "not-advanced-skip-button": "スキップ",
    "present-condition": "現状",

    // TOP BAR
    "topbar-all-groups-label": "全てのグループ",
    "topbar-all-addresses-label": "全ての地域",
    "topbar-all-ages-label": "全ての年代",
    "topbar-no-upper-limit-label": "上限なし",
    "topbar-filtering-label": "母集団絞り込み",
    "topbar-filter-conditions-label": "絞り込み条件",
    "topbar-number-cases-label": "該当件数",
    "topbar-from-label": "いつから",
    "topbar-to-label": "いつまで",

    ...dataManager,
    ...dashboard,
    ...account,
    ...shared,
    ...graph,
    ...auth,
  },
};

export default ja;
