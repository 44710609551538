import { apiClient } from "../../../libs/api";

import {
  DeleteIDCodeInputDTO,
  EditIDCodeInputDTO,
} from "../../domain/data-manager/type/id-code";
import {
  UploadFileRequest,
  StoreFileRequest,
} from "../../domain/data-manager/type/store";
import {
  DeleteGroupInputDTO,
  AddGroupRequest,
} from "../../domain/data-manager/type/group";
import {
  NodeHistogramRequest,
  EditNodeRequest,
} from "../../domain/data-manager/type/node";

export class DataManagerGateway {
  // INFO
  async fetchInfo(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/stored/info`;
    const response = await apiClient.get({ url: url });
    return response as any;
  }

  // ID CODE
  async fetchIDCode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/application`;
    const response = await apiClient.get({ url: url });
    return response as any;
  }

  async addIDCode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/service`;
    const response = await apiClient.post({ url: url });
    return response as any;
  }

  async editIDCode(request: EditIDCodeInputDTO): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/service/category`;
    const response = await apiClient.post({ url: url, body: request });
    return response as any;
  }

  async deleteIDCode(request: DeleteIDCodeInputDTO): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/service`;
    const response = await apiClient.remove({ url: url, body: request });
    return response as any;
  }

  // NODE
  async fetchNode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/nodes`;
    const response = await apiClient.get({ url: url });
    return response as any;
  }

  async editNode(request: EditNodeRequest): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data-manager/nodes`;
    const response = await apiClient.post({ url: url, body: request });
    return response as any;
  }

  async fetchNodeHistogram(request: NodeHistogramRequest): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data-manager/node/histogram`;
    const response = await apiClient.post({ url: url, body: request });
    return response as any;
  }

  // GROUP
  async fetchGroup(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/application`;
    const response = await apiClient.get({ url: url });
    return response as any;
  }

  async addGroup(request: AddGroupRequest): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/category`;
    const response = await apiClient.post({ url: url, body: request });
    return response as any;
  }

  async editGroup(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/category`;
    const response = await apiClient.post({ url: url, body: request });
    return response as any;
  }

  async deleteGroup(request: DeleteGroupInputDTO): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/category`;
    const response = await apiClient.remove({ url: url, body: request });
    return response as any;
  }

  // STRAGE
  async checkFile(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/check`;
    const response = await apiClient.get({ url: url });
    return response as any;
  }

  async uploadFile(request: UploadFileRequest): Promise<any> {
    const query = `store_type=${encodeURIComponent(request.store_type)}`;
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/upload?${query}`;
    const body = new FormData();
    request.files.forEach((file) => {
      body.append("files", file);
    });
    const response = await apiClient.uploadFile({ url: url, body: body });
    return response as any;
  }

  async storeFile(request: StoreFileRequest): Promise<any> {
    const query = `consent_type=${encodeURIComponent(request.consent_type)}`;
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/store?${query}`;
    const response = await apiClient.post({ url: url, body: request.data_ids });
    return response as any;
  }
}
