import { apiClient } from "../../../libs/api";

export class AuthGateway {
  // LOGIN
  async loginAccount(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signin`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  async setupAccount(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signup`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  async setupVerify(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signup/confirm`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  // MFA
  async setupMFA(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signin/mfa/setup`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  async verifyMFA(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signin/mfa`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  // FORGET
  async resetPassword(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/forgot`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  async verifyPassword(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/forgot/confirm`;
    const response = await apiClient.post({
      url: url,
      body: request,
      useToken: false,
    });
    return response as any;
  }

  // TERMS
  async checkTerms(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/auth/terms`;
    const response = await apiClient.get({
      url: url,
      useToken: true,
      token: request,
    });
    return response as any;
  }

  async verifyTerms(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/auth/terms`;
    const response = await apiClient.post({
      url: url,
      useToken: true,
      token: request,
    });
    return response as any;
  }

  // USER
  async fetchClient(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor`;
    const response = await apiClient.get({ url: url, token: request.token });
    return response as any;
  }

  async fetchUser(request: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/members`;
    const response = await apiClient.get({ url: url, token: request.token });
    return response as any;
  }

  // TRIAL
  async keepTrial(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/trial`;
    const response = await apiClient.post({ url: url });
    return response as any;
  }

  async skipTrial(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/trial`;
    const response = await apiClient.remove({ url: url });
    return response as any;
  }
}
