import { Member } from "../../../domain/account/type/member";

export class MemberAdapter {
  static toOutputDTO(response: any): Member[] {
    const output_dto: Member[] = response.data
      ? response.data.map((e: any) => ({
          login_user_id: e.user_id,
          client_id: e.vendor_id,
          email: e.email,
          name: e.name,
          role: e.status !== "" ? e.status : 0,
          stop_flg: e.stop_flg ? e.stop_flg : null,
          login_at: e.login_date !== "" ? e.login_date : null,
          pwd_flg: e.pwd_flg,
          updated_at: e.pwd_date !== "" ? e.pwd_date : null,
        }))
      : [];
    return output_dto;
  }
}
