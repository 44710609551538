import "./globals.css";
import "./i18n";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import * as React from "react";

import store from "./core/storage/legacy/store";
import { AuthRoute } from "./pkg/router";
import { Provider } from "./store";

import { Toaster } from "./components/ui/sonner";
import { Scroll } from "./components/scroll";

// COMPONENT
const Header = React.lazy(() => import("./pages/_shared/header/page"));
const Footer = React.lazy(() => import("./pages/_shared/footer/page"));

// AUTH
const LoginPage = React.lazy(() => import("./pages/auth/page"));
const MFAPage = React.lazy(() => import("./pages/auth/mfa/page"));
const TermsPage = React.lazy(() => import("./pages/auth/mfa/terms/page"));
const SetupPage = React.lazy(() => import("./pages/auth/setup/page"));
const SetupCompletePage = React.lazy(
  () => import("./pages/auth/setup/complete/page")
);
const ForgetPage = React.lazy(() => import("./pages/auth/forget/page"));
const ForgetCompletePage = React.lazy(
  () => import("./pages/auth/forget/complete/page")
);

// MENU
const SetupAccountPage = React.lazy(
  () => import("./pages/account/setup/legacy")
);
const SetupAccountCompletePage = React.lazy(
  () => import("./pages/account/setup/complete/page")
);
const AccountPage = React.lazy(() => import("./pages/account/page"));
const ProfilePage = React.lazy(() => import("./pages/account/profile/page"));
const MemberPage = React.lazy(() => import("./pages/account/member/page"));
const BillingPage = React.lazy(() => import("./pages/account/plan/legacy"));
const PlanPage = React.lazy(() => import("./pages/account/plan/page"));
const DebugPage = React.lazy(() => import("./pages/account/debug/page"));
const PricePage = React.lazy(() => import("./pages/account/_price/page"));
const InvoicePage = React.lazy(() => import("./pages/account/invoice/page"));
const DashboardPage = React.lazy(() => import("./pages/dashboard/page"));
const GraphPage = React.lazy(() => import("./pages/graph/page"));
const DataManagerPage = React.lazy(() => import("./pages/data-manager/page"));
const IDCodePage = React.lazy(
  () => import("./pages/data-manager/id-code/page")
);
const NodePage = React.lazy(() => import("./pages/data-manager/node/page"));
const PriorPage = React.lazy(() => import("./pages/data-manager/prior/page"));
const GroupPage = React.lazy(() => import("./pages/data-manager/group/page"));
const MaintainPage = React.lazy(() => import("./pages/maintain/page"));
const NotFound = React.lazy(() => import("./pages/not-found"));
const SupportPage = React.lazy(() => import("./pages/support/page"));

// LEGACY
const Dashboard = React.lazy(() => import("./pages/dashboard/legacy/page"));
const CausalGraph = React.lazy(() => import("./pages/graph/legacy/page"));
const DataLinkageView = React.lazy(
  () => import("./pages/data-manager/legacy/page")
);
const DiscretizationView = React.lazy(
  () => import("./pages/data-manager/legacy/discretization")
);

const App: React.FC = () => {
  return (
    <Scroll>
      <Header />
      <main>
        <React.Suspense fallback={<div style={{ height: "100vh" }} />}>
          <Routes>
            <Route path="/:lang?/login" element={<LoginPage />} />
            <Route path="/:lang?/login/mfa" element={<MFAPage />} />
            <Route path="/:lang?/login/mfa/terms" element={<TermsPage />} />
            <Route path="/:lang?/setup" element={<SetupPage />} />
            <Route
              path="/:lang?/setup/complete"
              element={<SetupCompletePage />}
            />
            <Route path="/:lang?/forget" element={<ForgetPage />} />
            <Route
              path="/:lang?/forget/complete"
              element={<ForgetCompletePage />}
            />
            <Route element={<AuthRoute />}>
              <Route
                path="/:lang?/account/setup"
                element={<SetupAccountPage />}
              />
              <Route
                path="/:lang?/account/setup/complete"
                element={<SetupAccountCompletePage />}
              />
              <Route path="/:lang?/wip/dashboard" element={<DashboardPage />} />
              <Route path="/:lang?/wip/graph" element={<GraphPage />} />
              <Route path="/:lang?/data-manager" element={<DataManagerPage />}>
                <Route index element={<IDCodePage />} />
                <Route path="linkage" element={<DataLinkageView />} />
                <Route
                  path="linkage/discretization"
                  element={<DiscretizationView />}
                />
                <Route path="node" element={<NodePage />} />
                <Route path="prior" element={<PriorPage />} />
                <Route path="group" element={<GroupPage />} />
              </Route>
              <Route path="/:lang?/account" element={<AccountPage />}>
                <Route index element={<ProfilePage />} />
                <Route path="member" element={<MemberPage />} />
                <Route path="billing" element={<BillingPage />} />
                <Route path="plan" element={<PlanPage />} />
                <Route path="price" element={<PricePage />} />
                <Route path="invoice" element={<InvoicePage />} />
                <Route path="debug" element={<DebugPage />} />
              </Route>
              <Route path="/:lang?/support" element={<SupportPage />} />
              <Route path="/:lang?/dashboard" element={<Dashboard />} />
              <Route path="/:lang?/graph" element={<CausalGraph />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </React.Suspense>
      </main>
      <Footer />
    </Scroll>
  );
};

const rootElement =
  document.getElementById("root") || document.createElement("div");
const root = createRoot(rootElement);

const maintainMode = false;

root.render(
  <React.Fragment>
    {maintainMode ? (
      <MaintainPage />
    ) : (
      <CookiesProvider>
        <BrowserRouter>
          <Provider>
            <ReduxProvider store={store}>
              <App />
              <Toaster />
            </ReduxProvider>
          </Provider>
        </BrowserRouter>
      </CookiesProvider>
    )}
  </React.Fragment>
);
