import { getModel, isAdvanced, dateFormatter } from "./helper";
import store from "../../core/storage/legacy/store";
import {
  setStratificationFuncOn,
  setStratificationSubgroup,
} from "../../core/storage/legacy/causal-graph";
import { apiClient } from "../api";
import i18n from "../../i18n";

export const getClientInfo = async () => {
  const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const getMetadata = async ({ signal }) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/metadata`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
    signal: signal,
  });
  return response;
};

export const getDashboard = async ({
  userIds,
  groups,
  ages,
  genders,
  start,
  end,
  condition = "condition",
  signal,
}) => {
  const body = {
    user_code_list: userIds,
    group: groups,
    age: ages,
    gender: genders,
    start_date: dateFormatter(start),
    end_date: dateFormatter(end),
    column: condition,
    is_past_look: true,
  };
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/dashboard`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
    signal: signal,
  });
  return response;
};

export const getReportColumns = async (conditions) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/${conditions.id}/report_columns`;
  const response = await apiClient.post({
    url: url,
    body: conditions,
    useToken: true,
  });
  return response;
};

export const getDistribution = async (conditions) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/distribution`;
  const response = await apiClient.post({
    url: url,
    body: {
      user_code_list: conditions.userIds,
      start_date: dateFormatter(conditions.startDate),
      end_date: dateFormatter(conditions.endDate),
      column: conditions.outcomeNode ? conditions.outcomeNode : "condition",
      time_unit: conditions.timeUnit ? conditions.timeUnit : "one_day",
    },
    useToken: true,
  });
  return response;
};

export const getHistogram = async (conditions) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/histogram`;
  const response = await apiClient.post({
    url: url,
    body: {
      user_code_list: conditions.userIds,
      start_date: dateFormatter(conditions.startDate),
      end_date: dateFormatter(conditions.endDate),
      column: conditions.outcomeNode ? conditions.outcomeNode : "condition",
      time_unit: conditions.timeUnit ? conditions.timeUnit : "one_day",
    },
    useToken: true,
  });
  return response;
};

export const getDemographic = async (conditions) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/causal_demographic`;
  const body = {
    user_code_list: conditions.user_code_list,
    group: conditions.group,
    age: conditions.age,
    gender: conditions.gender,
    start_date: dateFormatter(conditions.start_date),
    end_date: dateFormatter(conditions.end_date),
  };
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const getOutcomeColumns = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/outcome_columns`;
  const response = await apiClient.post({
    url: url,
    body: {
      ...body,
      start_date: dateFormatter(body.start_date),
      end_date: dateFormatter(body.end_date),
    },
    useToken: true,
  });
  return response;
};

export const getRelevanceColumns = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/relevance_columns`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const getColumnsUser = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/columns_user`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const resetKPIItems = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/clients/users/settings/labels`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const saveKPIItems = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/clients/users/settings/labels`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const getSubgroupLabel = async (conditions) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/clients/users/subgroup/labels`;
  const response = await apiClient.post({
    url: url,
    body: conditions,
    useToken: true,
  });
  return response;
};

export const getSubgroup = async (label) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/clients/users/settings/subgroup/${label}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const saveSubgroup = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/clients/users/settings/subgroup`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const resetSubgroup = async (label) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/clients/users/settings/subgroup/${label}`;
  const response = await apiClient.remove({
    url: url,
    useToken: true,
  });
  return response;
};

export const getAnalyses = async ({ page }) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/analysis?page=${page}&items_per_page=5`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const getModels = async ({ analysis_id, page }) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/analysis/${analysis_id}/${getModel()}?page=${page}&items_per_page=5&is_fixed=true`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const getDWH = async ({ vendor_id_list, signal }) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/dwh/datawarehouse_day_preprocessed?vendor_id_list=${vendor_id_list
    .map((n, index) => (vendor_id_list[index] = n))
    .join("&")}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
    signal: signal,
  });
  return response;
};

export const getBNModel = async ({ newModelId, signal }) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}/${newModelId}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
    signal: signal,
  });
  return response;
};

export const updateBNModel = async (model_id, name) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}`;
  const response = await apiClient.update({
    url: url,
    body: { name },
    useToken: true,
  });
  return response;
};

export const copyWithReports = async (model_id, analysis_id) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}/${model_id}/copy-with-reports?analysis_id=${analysis_id}`;
  const response = await apiClient.post({
    url: url,
    useToken: true,
  });
  return response;
};

export const deleteBNModel = async (model_id) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}`;
  const response = await apiClient.remove({
    url: url,
    useToken: true,
  });
  return response;
};

export const addNode = async (model_id, nodes) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/node`;
  const response = await apiClient.post({
    url: url,
    body: { nodes },
    useToken: true,
  });
  return response;
};

export const updateOutcomeNode = async (model_id, nodes) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/node`;
  const response = await apiClient.post({
    url: url,
    body: { nodes },
    useToken: true,
  });
  return response;
};

export const deleteNode = async (model_id, nodes) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/node`;
  const response = await apiClient.remove({
    url: url,
    body: { nodes },
    useToken: true,
  });
  return response;
};

export const addEdge = async (model_id, edges) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/edge`;
  const response = await apiClient.post({
    url: url,
    body: { edges },
    useToken: true,
  });
  return response;
};

export const deleteEdge = async (model_id, edges) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/edge`;
  const response = await apiClient.remove({
    url: url,
    body: { edges },
    useToken: true,
  });
  return response;
};

export const searchStructure = async (
  model_id,
  equivalent_sample_size = 30,
  algoType = "hillclimb"
) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/data-robustness/bnmodel/${model_id}/limited-structure-search`;
  const response = await apiClient.post({
    url: url,
    body: {
      algorithm_type: algoType,
      start_dag_edges: [],
      fixed_edges: [],
      black_list: [],
      white_list: [],
      max_indegree: 3,
      structure_search_config: {
        algorithm_type: algoType,
        score_type: "bdeuscore",
        equivalent_sample_size: 30,
      },
    },
    useToken: true,
  });
  return response;
};

const pollMessageQueue = async (task_id) => {
  return await new Promise((resolve) => {
    let intervalId = setInterval(async () => {
      let statusResult = await getStatus(task_id);
      if (statusResult !== undefined && statusResult.status) {
        clearInterval(intervalId);
        let finalResult = await getResult(task_id);
        return resolve(finalResult);
      }
    }, 10000);
  });
};

export const estimateParameter = async (
  model_id,
  equivalent_sample_size = 30,
  estimate_refute = false,
  useQueue = false,
  retry = true
) => {
  let shouldUseQueue = useQueue;

  let body = {
    prior_type: "BDeu",
    equivalent_sample_size: equivalent_sample_size,
  };

  if (isAdvanced()) {
    body = {
      estimate_effect_method_type: "backdoor.generalized_linear_model",
      prior_type: "BDeu",
      equivalent_sample_size: equivalent_sample_size,
      estimate_refute: estimate_refute,
    };
  }

  let selectedURL = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/data-robustness`;
  let selectedPath = `/${getModel()}/${model_id}/limited-parameter-estimation`;

  if (shouldUseQueue) {
    selectedURL = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/message-queue`;
    selectedPath = `/data-robustness/${model_id}/limited-parameter-estimation`;
  }

  try {
    const url = `${selectedURL}${selectedPath}`;
    const response = await apiClient.post({
      url: url,
      body: body,
      useToken: true,
    });
    if (shouldUseQueue && response && response.task_id) {
      return await pollMessageQueue(response.task_id);
    } else {
      return response;
    }
  } catch (error) {
    if (isAdvanced() && retry) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      estimateParameter(
        model_id,
        equivalent_sample_size,
        estimate_refute,
        useQueue,
        false
      );
    }
  }
};

export const getStatus = async (task_id) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/message-queue/status?task_id=${task_id}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const getResult = async (task_id) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/message-queue/result?task_id=${task_id}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const fixBNModel = async (model_id) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/fix`;
  const response = await apiClient.post({
    url: url,
    useToken: true,
  });
  return response;
};

export const edgeAvailability = async ({
  model_id,
  add_edges = [],
  reverse_edges = [],
}) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/edge-availability`;
  const response = await apiClient.post({
    url: url,
    body: {
      add_edges,
      reverse_edges,
    },
    useToken: true,
  });
  return response;
};

export const renameReport = async (bnmodel_report_id, name) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}-report/${bnmodel_report_id}`;
  const response = await apiClient.update({
    url: url,
    body: { name },
    useToken: true,
  });
  return response;
};

export const getDoReport = async ({ model_id, page }) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}/${model_id}/do${
    isAdvanced() ? "-dw" : ""
  }?page=${page}&items_per_page=3&is_fixed=true`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const getReport = async (model_id) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}/${model_id}/${getModel()}-report`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const computeDo = async (model_id, schema, retry = true) => {
  let selectedURL = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/data-robustness`;
  let selectedPath = `/${getModel()}/${model_id}/do${
    isAdvanced() ? "-dw" : ""
  }`;

  try {
    const url = `${selectedURL}${selectedPath}`;
    const response = await apiClient.post({
      url: url,
      body: schema,
      useToken: true,
    });

    return response;
  } catch (error) {
    if (isAdvanced() && retry) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      computeDo(model_id, schema, false);
    }
  }
};

export const getMedian = async (model_id, node_id) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${model_id}/node/${node_id}/distribution`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const fixModelReport = async (reportId) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}-report/${reportId}/fix`;
  const response = await apiClient.post({
    url: url,
    useToken: true,
  });
  return response;
};

export const deleteReport = async (reportId) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}-report/${reportId}`;
  const response = await apiClient.remove({
    url: url,
    useToken: true,
  });
  return response;
};

export const reportPublish = async ({ reportId }) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}-report-do/${reportId}/publish`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const reportPublishCompare = async ({ reportId1, reportId2 }) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/${getModel()}-report-do/${reportId1}/publish-with/${reportId2}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const getDataset = async ({ bnmodel }) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/dataset/${bnmodel.dataset_metadata_id}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  if (isAdvanced() && response && response.subgrouping_conditions) {
    store.dispatch(setStratificationFuncOn(true));
    if (response.subgrouping_conditions.length > 2) {
      store.dispatch(
        setStratificationSubgroup(response.subgrouping_conditions[0])
      );
    }
  } else {
    store.dispatch(setStratificationFuncOn(false));
  }
  return response;
};

export const getJournalSearch = async ({ var1, var2, id, lang, page = 1 }) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/journal_search?var1=${var1}&var2=${var2}&lang=${lang}&report_id=${id}&page=${page}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const stratificationDo = async (model_id, schema) => {
  let selectedUrl = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference`;
  if (isAdvanced()) {
    selectedUrl = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/data-robustness`;
  }
  const path = `/${getModel()}/${model_id}/subgrouping-do${
    isAdvanced() ? "-dw" : ""
  }`;

  const url = `${selectedUrl}${path}`;
  const response = await apiClient.post({
    url: url,
    body: schema,
    useToken: true,
  });
  return response;
};

export const checkMediator = async ({ model_id, schema }) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/dwmodel/${model_id}/check-mediator`;
  const response = await apiClient.post({
    url: url,
    body: schema,
    useToken: true,
  });
  return response;
};

export const computeMediator = async ({ model_id, schema }) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/data-robustness/dwmodel/${model_id}/mediator`;
  const response = await apiClient.post({
    url: url,
    body: schema,
    useToken: true,
  });
  return response;
};

export const fetchAllAnalysis = async () => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/analysis?page=1&items_per_page=1000`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response.analyses;
};

export const fetchAllDatasets = async (analysisId) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/analysis/${analysisId}/dataset?page=1&items_per_page=1000`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response.datasets;
};

export const getNodeProperty = async (t, vendorID) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/dwh/datawarehouse_day_preprocessed?vendor_id_list=${vendorID}`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const makeDataset = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/dataset`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const makeModel = async (body) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel`;
  const response = await apiClient.post({
    url: url,
    body: body,
    useToken: true,
  });
  return response;
};

export const makeAnalysis = async (name) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/analysis`;
  const response = await apiClient.post({
    url: url,
    body: { name },
    useToken: true,
  });
  return response;
};

export const limitedStructureSearch = async (
  modelID,
  algoType = "hillclimb"
) => {
  let schema = {
    algorithm_type: algoType,
    start_dag_edges: [],
    fixed_edges: [],
    black_list: [],
    white_list: [],
    max_indegree: 3,
    structure_search_config: {
      algorithm_type: algoType,
      score_type: "bdeuscore",
      equivalent_sample_size: 30,
    },
  };

  if (algoType === "pc") {
    schema = {
      algorithm_type: algoType,
      start_dag_edges: [],
      fixed_edges: [],
      black_list: [],
      white_list: [],
      max_indegree: 3,
      structure_search_config: {
        algorithm_type: algoType,
        ci_test: "chi_square",
        max_cond_vars: 5,
      },
    };
  } else if (algoType === "mmhc") {
    schema = {
      algorithm_type: algoType,
      start_dag_edges: [],
      fixed_edges: [],
      black_list: [],
      white_list: [],
      max_indegree: 3,
      structure_search_config: {
        algorithm_type: algoType,
        score_type: "bdeuscore",
        equivalent_sample_size: 30,
        ci_test: "chi_square",
        max_cond_vars: 5,
      },
    };
  }

  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/api/data-robustness/bnmodel/${modelID}/limited-structure-search`;
  const response = await apiClient.post({
    url: url,
    body: schema,
    useToken: true,
  });
  return response;
};

export const emptyDatasetPreset = {
  analysis_id: "7d1f0804-295f-4c36-ab43-be11829c80b9",
  name: "Fukushima test for empty condition",
  gender_condition: {
    male: false,
    female: false,
    other: false,
  },
  age_condition: [],
  user_code_list: [
    "7b89296c-6dcb-ac50-0857-7eb1924770d3",
    "3bb427c1-a1da-059d-2ad1-245c92010b38",
    "277582f0-93f5-0c2c-488e-44f94ecc6c7f",
  ],
  start_period_condition: "2022-01-01T15:57:57",
  end_period_condition: "2022-03-01T15:57:57",
  tag_list: [],
  column_list: [
    "condition",
    "stepCount",
    "walkingSpeed",
    "walkingStepLength",
    "weather",
    "sleep",
    "heartRate",
    "tempC",
    "pressure",
    "uvIndex",
  ],
  vendor_id_list: [],
  group_condition: [],
  enable_nondiscretized: false,
};

export const deleteAnalysis = async (analysisID) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/analysis/${analysisID}`;
  await apiClient.remove({
    url: url,
    useToken: true,
  });
  return;
};

export const getModelsFromAnalysis = async (analysisID) => {
  const url = `${
    process.env.REACT_APP_XCAUSAL_PLATFORM_URL
  }/causal-inference/analysis/${analysisID}/${getModel()}?page=1&items_per_page=1000&is_fixed=true`;
  const response = await apiClient.get({
    url: url,
    useToken: true,
  });
  return response;
};

export const fixModel = async (modelID) => {
  const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/causal-inference/bnmodel/${modelID}/fix`;
  const response = await apiClient.post({
    url: url,
    useToken: true,
  });
  return response;
};
