import { apiClient } from "../../../libs/api";

import { DemographicReq } from "../../domain/dashboard/type/demographic";
import { TrendInputDTO } from "../../domain/dashboard/type/trend";

export class DashboardGateway {
  async fetchDemographic(request: DemographicReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/demographic`;
    const response = await apiClient.post({ url: url, body: request });
    return response as any;
  }

  async fetchTrend(request: TrendInputDTO): Promise<any> {
    const trend_url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/trend_with_condition`;
    const distribution_url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/distribution_with_condition`;
    const requests = [
      apiClient.post({ url: trend_url, body: request }).then((response) => {
        return response;
      }),
      apiClient
        .post({ url: distribution_url, body: request })
        .then((response) => {
          return response;
        }),
    ];
    const [trend_data, distribution_data] = await Promise.all(requests);
    return { trend: trend_data, distribution: distribution_data };
  }
}
