import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClientInfo } from "../../../libs/legacy/api";

const initialState = {
  spinning: false,
  vendorData: null,
};

export const fetchClientInfo = createAsyncThunk(
  "vendor/fetchVendor",
  async () => {
    const response = await getClientInfo();
    return response;
  }
);

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    startSpinning: (state) => {
      state.spinning = true;
    },
    stopSpinning: (state) => {
      state.spinning = false;
    },
    setVendorData: (state, action) => {
      state.vendorData = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientInfo.pending, () => {})
      .addCase(fetchClientInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.vendorData = action.payload;
        } else {
          state.vendorData = null;
        }
      });
  },
});

export const { startSpinning, stopSpinning, setVendorData } =
  vendorSlice.actions;

export const selectSpinner = (state) => state.vendor.spinning;
export const selectVendorData = (state) => state.vendor.vendorData;

export default vendorSlice.reducer;
