import { APIStatus } from "../../../domain/_shared/type/status";
import {
  IDCode,
  EditIDCodeRequest,
  EditIDCodeInputDTO,
  DeleteIDCodeRequest,
  DeleteIDCodeInputDTO,
} from "../../../domain/data-manager/type/id-code";

export class IDCodeAdapter {
  static toEditIDCodeInputDTO(request: EditIDCodeRequest): EditIDCodeInputDTO {
    const input_dto: EditIDCodeInputDTO = {
      category_id: request.group.id,
      user_code: request.id_code,
    };
    return input_dto;
  }

  static toDeleteIDCodeInputDTO(
    request: DeleteIDCodeRequest
  ): DeleteIDCodeInputDTO {
    const input_dto: DeleteIDCodeInputDTO = {
      user_code: request.id_code,
    };
    return input_dto;
  }

  static toIDCodeOutputDTO(response: any): IDCode[] {
    const output_dto: IDCode[] = response.service
      ? response.service.map((e: any) => ({
          id: e.id,
          id_code: e.id_code,
          status: e.is_code_status,
          integration_code: e.youd_user_id ?? null,
          group: {
            id: e.subject_group.id ?? null,
            name: e.subject_group.name ?? null,
          },
          description: e.memo ?? null,
        }))
      : [];
    return output_dto;
  }

  static toIDCodeStatus(response: any): APIStatus {
    const output_dto: APIStatus = {
      status: response.status,
      data: response.data,
    };
    return output_dto;
  }
}
