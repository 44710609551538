import {
  DemographicRes,
  Demographic,
  RatioItems,
  BarEntry,
} from "../../../domain/dashboard/type/demographic";

export class DemographicAdapter {
  static toOutputDTO(response: DemographicRes): Demographic {
    const bar_data = this._convertToBarEntry(response);
    const ratio_data = this._convertToRatioEntry(bar_data);

    console.log({ bar_data, ratio_data });

    return {
      bar: bar_data,
      ratio: ratio_data,
    } as Demographic;
  }

  private static _convertToBarEntry = (props: DemographicRes): BarEntry => {
    const data: { [key: string]: { male: number; female: number } } = {};

    props.forEach(({ gender, age, user_count }) => {
      age.forEach((age_group, index) => {
        const ageRange = `${age_group}0s`;
        const count = user_count[index];
        const gender_key = gender === "0" ? "male" : "female";

        data[ageRange] = data[ageRange] || { male: 0, female: 0 };
        data[ageRange][gender_key] += count;
      });
    });

    return Object.entries(data).map(([id, counts]) => ({
      id,
      ...counts,
    }));
  };

  private static _convertToRatioEntry = (
    props: BarEntry
  ): {
    gender: RatioItems;
    age: RatioItems;
  } => {
    const gender = [
      { id: "male", value: props.reduce((sum, item) => sum + item.male, 0) },
      {
        id: "female",
        value: props.reduce((sum, item) => sum + item.female, 0),
      },
    ];

    const age = props.map(({ id, male, female }) => ({
      id,
      value: male + female,
    }));

    return { gender, age };
  };
}
