import { Invoice } from "../../../domain/account/type/invoice";

export class InvoiceAdapter {
  static toOutputDTO(response: any): Invoice[] {
    const output_dto: Invoice[] = response.data.data
      ? response.data.data.map((e: any) => ({
          id: e.number,
          date: e.created,
          price: e.total,
          stripe: e.invoice_url,
        }))
      : [];
    return output_dto;
  }
}
