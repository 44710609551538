import dataManager from "./data-manager";
import dashboard from "./dashboard";
import account from "./account";
import shared from "./shared";
import graph from "./graph";
import auth from "./auth";

const en = {
  translation: {
    "btn-regist": "Register",
    "btn-edit": "Edit",
    "btn-delete": "Delete",
    "btn-back": "Back",
    "btn-billing": "Subscribe",
    "btn-send": "Send",
    "btn-confirm": "Confirm",
    "btn-regist2": "Register",
    "btn-card": "Add Credit Card",
    "btn-add": "Add",
    "btn-mail": "Mail",
    "btn-plan-cancel": "Unsubscribe",
    "btn-leave": "Withdrawal",
    "btn-ok": "OK",

    "header-corporate": "Corporate Site",
    "header-service": "Service",
    "header-wellness": "Wellness",
    "header-dashboard": "Dashboard",
    "header-graph": "Causal Graph",
    "header-data": "Data Mgmt.",
    "header-account": "Account",
    "header-support": "Document",
    "header-logout": "Logout",
    "header-login": "Login",

    "support-manual": "Manual",
    "support-manual-basic": "Manual (Causal Inference Basic Version)",
    "support-manual-advanced": "Manual (Causal Inference Advanced Version)",
    "support-whitepaper": "White Paper",
    "support-whitepaper-basic": "White Paper (Causal Inference Basic Version)",
    "support-whitepaper-advanced":
      "White Paper (Causal Inference Advanced Version)",

    "user-registration": "ID Code Management",
    "personal-code": "ID Code",
    "youd-user-id": "youd User ID",
    "usage-status": "Usage Status",
    "usage-status-unused": "Unused",
    "usage-status-expired": "Expired",
    "usage-status-in-use": "In Use",
    "usage-status-unregistered": "Unregistered",
    "app-code-desc-1":
      "In order to link the user data of the you'd app with the data you have, please obtain ID codes here and distribute them to users by email, etc.\nSince we do not retain personal information, please manage your ID codes and user information.",
    "app-code-num": "Number of codes linked to apps",
    "app-code-add": "Add ID Code",
    "app-code-download": "Download CSV",
    "app-code-list-id": "User ID",
    "app-group": "Group",
    "select-all": "Select All",
    "app-status-not-set": "Not Set",
    "app-status-unpublished": "Unpublished",
    "app-code-show-more": "Show More",
    "app-category-title": "Group Management",
    "app-category-desc":
      "Groups can be added and managed by assigning them to any ID code.",

    "regist-vendor-title": "Company Information Registration",
    "vendor-name": "Company",
    "vendor-postcode": "Postal Code",
    "vendor-address": "Address",
    "vendor-department": "Department",
    "vendor-position": "Job Title",
    "vendor-manager": "Full Name",
    "vendor-phone": "Telephone",
    "vendor-email": "Email",
    "vendor-logo": "Company Logo",
    "vendor-comma": ", ",

    "vendor-country": "Country",
    "vendor-prefecture": "Prefecture",
    "vendor-city": "City",

    "vendor-plan-1-months": "1 Months",
    "vendor-plan-6-months": "6 Months",
    "vendor-plan-1-year": "1 Year",

    "vendor-plan-basic": "Basic Version",
    "vendor-plan-basic-desc":
      " ダッシュボード\n 因果探索(3種類)\n 因果推論\n Smallyticsアルゴリズム\n 離散値のみ対応",

    "vendor-plan-advanced": "Advanced Version",
    "vendor-plan-advanced-desc":
      " ダッシュボード\n 因果探索(3種類)\n 因果推論\n Smallyticsアルゴリズム\n 離散値と連続値の混在利用可\n 層別化解析\n 直接(NDE)・間接効果(NIE)の推定\n 頑健性評価\n 論文検索機能",

    "vendor-plan-code": "データ数課金プラン",
    "vendor-plan-code-desc":
      "基本料金 + データ数(xCausalで発行する識別コード数)による課金\n 5アカウントまで基本サービスに含む",

    "vendor-plan-cap": "容量課金プラン",
    "vendor-plan-cap-desc":
      "基本料金(1GBまで) ＋ 追加データ容量(1GB単位)による課金\n 5アカウントまで基本サービスに含む\n リファレンスへご協力いただける場合、初年度基本料金を15％ディスカウントン",

    "vendor-plan-reference":
      "※ リファレンスへご協力いただける場合、個別にお問い合わせください",

    "vendor-plan-5-accounts": "・5 accounts (including administrator)",
    "vendor-plan-personal-codes": "・Provide personal codes for user linkage",
    "vendor-plan-healthcare-dashboard": "・Healthcare Dashboard",
    "vendor-plan-user-health-dashboard": "・User health dashboard (individual)",
    "vendor-plan-data-upload": "・Upload your own data",
    "vendor-plan-causal-basic": "・Causal Inference Basic Version",
    "vendor-plan-bayesian-version": " Bayesian Network Version",
    "vendor-plan-10-nodes-included": " (Up to 10 nodes) ",
    "vendor-plan-1-month-free": " 1 Month Free Trial (5 Nodes)",
    "vendor-plan-6-month-plan": "6 Month Plan",
    "vendor-plan-1-year-plan": "1 Year Plan",
    "vendor-plan-basic-charge": "Basic Charge:",
    "vendor-plan-additional-fee": "Additional Fee:",
    "vendor-plan-150000": "150,000円",
    "vendor-plan-240000": "240,000円",
    "vendor-plan-375000": "375,000円",
    "vendor-plan-600000": "600,000円",
    "vendor-plan-per-user": "Per User:",
    "vendor-plan-150-month": "150円 / Month",
    "vendor-plan-200-month": "200円 / Month",
    "vendor-plan-300-month": "300円 / Month",
    "vendor-plan-400-month": "400円 / Month",
    "vendor-plan-future-release": "Scheduled for Future Release",
    "vendor-plan-add-accounts": "Add Accounts",
    "vendor-plan-5-set": " (5 Accounts)",
    "vendor-plan-sets-of-5":
      "Additional accounts can be purchased in sets of 5.",
    "vendor-plan-quantity": "Quantity",
    "vendor-plan-confirm-changes": "Confirm",
    "vendor-plan-contract-details-title": "Contract Details Confirmation",
    "vendor-plan-contract-service": "Contract Service",
    "vendor-plan-contract-service-detail":
      "Fees are billed as a basic fee for the contract period (lump-sum payment) and a monthly fee (monthly payment) based on the number of app-linked users.",
    "vendor-plan-service-menu-basic": "Service Menu Basic Charge",
    "vendor-plan-contract-period": "Contract Period",
    "vendor-plan-basic-charge-2": "Basic Charge",
    "vendor-plan-no-change": " (no change)",
    "vendor-plan-total": "合計 (税込)",
    "vendor-plan-monthly-code-fee": "データ数に基づく月額料金",
    "vendor-plan-monthly-cap-fee": "データ容量に基づく月額料金",
    "vendor-plan-monthly-usage-fee": "月額利用料金",
    "vendor-plan-monthly-code-fee-per": "1データあたりの月額 (税込)",
    "vendor-plan-monthly-cap-fee-per": "1GBあたりの月額 (税込)",

    "vendor-plan-service-changed": "Service Updated",
    "vendor-plan-service-change-1":
      "We have updated the content of the contract.",
    "vendor-plan-service-change-2":
      "Please wait a moment until it is reflected on the dashboard.",

    "plan-desc-health-5":
      "you'd app user statistics available (individual quotation)",
    "plan-desc-opt-basic-1": "・Node recommendation by Smallytics",
    "plan-desc-opt-basic-2": "・Bayesian network",
    "plan-desc-opt-basic-3": "・Used by discretization (discrete values only)",
    "plan-desc-opt-advance-1": "・Node recommendation by Smallytics",
    "plan-desc-opt-advance-2": "・Bayesian network/SCM",
    "plan-desc-opt-advance-3": "・Mixed use of discrete and continuous values",
    "plan-desc-opt-advance-4":
      "・Natural Direct Effect (NDE) Natural Indirect Effect (NIE)",
    "plan-desc-opt-advance-5": "・Robustness evaluation",
    "plan-desc-opt-advance-6": "・Journal search function",

    "plan-period-1-month": "Monthly plan",
    "plan-period-6-month": "Half year plan",
    "plan-period-1-year": "Annual plan",
    "plan-price-table-1-1": "ベーシック・バージョン(月額)(税込)",
    "plan-price-table-1-1-2": "５アカウント分含む",
    "plan-price-table-1-2": "アドバンス・バージョン(月額)(税込)",
    "plan-price-table-2-1": "Basic Charge",
    "plan-price-table-2-2": "データ数課金分",
    "plan-price-table-2-3": "Add Accounts",

    "plan-price-table-cap-1-1-2": "容量1GB・５アカウント分含む",
    "plan-price-table-cap-2-2": "容量課金分",

    "plan-youd-option": "you’d linkage option",
    "youd-content-title-1": "",
    "youd-content-title-2": "",
    "youd-content-title-3": "you'd Integration Analytics Service",
    "youd-content-guide-1-1": "",
    "youd-content-guide-1-2": "you'd™︎",
    "youd-content-guide-1-3":
      " is a conditioning AI application that analyzes various information such as life logs accumulated on smartphones from wearable devices, records of physical condition and habits entered by yourself, and environmental data such as weather and atmospheric pressure.",
    "youd-content-guide-2-1": "By integrating with ",
    "youd-content-guide-2-2": "you'd™︎",
    "youd-content-guide-2-3":
      " and combining it with proprietary data held by the subscriber’s company, more advanced and customized data analysis becomes possible. This enables more accurate analysis results and insights for business decision-making and strategy formulation.",
    "youd-contenxt-popup":
      "If you want to choose the you’d linkage option, we require you apply for it when you sign up. The you’d is free app. It is not possible to switch to the you’d linkage option during the contract. If you want to switch in the middle of your contract, you will need to do the initial setup. Please kindly note in advance.",

    "vendor-plan-monthly-code-1": "データ数",
    "vendor-plan-monthly-code-2": "の時の月額換算(税込)",
    "vendor-plan-monthly-cap-1": "データ容量",
    "vendor-plan-monthly-cap-2": "GBの時の月額換算(税込)",

    "regist-vendor-plan-custom":
      "Quotations and custom support other than the above",

    "vendor-plan-custom-data": "データ対応",
    "plan-desc-custom-data-1": "・1,000データ数/100項目以上のデータ数課金",
    "plan-desc-custom-data-2": "・10GBを超えるデータ容量課金",
    "plan-desc-custom-data-3": "・ヴェルト社の保有するデータ利用",
    "plan-desc-custom-data-4": "・サード・パーティーのデータ購入利用",

    "vendor-plan-custom-individual": "カスタム開発・プロフェッショナルサービス",
    "plan-desc-custom-individual-1": "・画面・機能のカスタム開発",
    "plan-desc-custom-individual-2": "・専用AI環境構築による利用",
    "plan-desc-custom-individual-3": "・ユーザー企業のシステムとの連携/統合",
    "plan-desc-custom-individual-4": "・導入/運用コンサルティング",
    "plan-desc-custom-individual-5": "・当社による解析・レポーティング",

    "vendor-plan-custom-partnership": "パートナーシップ",
    "plan-desc-custom-partnership-1": "・コンサルティング型パートナーシップ",
    "plan-desc-custom-partnership-2": "・付加価値再販型パートナーシップ",
    "plan-desc-custom-partnership-3": "・Smallyticsアルゴリズムの供給",
    "plan-desc-custom-partnership-4": "・you’dアプリのOEM供給",
    "regist-vendor-inquiry": "contact us",

    "regist-vendor-complete-title": "Completion of Company Registration",
    "regist-vendor-complete-message":
      "We are processing the payment. \nPlease wait a moment.",
    "regist-vendor-inspection-title": "Confirming Company Information",
    "regist-vendor-inspection-message": "Checking. \nPlease wait a moment.",
    "regist-vendor-ready-title": "Company Information Confirmation Complete",
    "regist-vendor-ready-message":
      "Your company information has been confirmed. Please register your payment information.",
    "regist-vendor-select-service": "Select Service",
    "regist-vendor-plan-desc-1":
      "xCausal(クロス・コーザル)では「ベーシック・バージョン」と「アドバンス・バージョン」の2つのメニューをご用意しております。",
    "regist-vendor-plan-desc-2":
      "ベーシック・バージョン\nデータを可視化するダッシュボードと、因果探索(3種類)と因果推論を行うことが出来ます。\n因果推論時に、Smallyticsという因果性を考慮した変数推奨アルゴリズムを利用可能です。\n離散値のみで計算処理に対応しています。",
    "regist-vendor-plan-desc-3":
      "アドバンス・バージョン\nデータを可視化するダッシュボードと、因果探索(3種類)と因果推論を行うことが出来ます。\n因果推論時に、Smallyticsという因果性を考慮した変数推奨アルゴリズムを利用可能です。\n離散値に加えて連続値での計算処理に対応しています。\nまた、上記に加え「層別化解析」「直接・間接効果の推定」といった詳細な解析機能と「頑健性評価」「論文検索機能」といった信頼性確保の機能が利用可能です。\n今後も新機能を追加予定です。",
    "regist-vendor-plan-desc-4":
      "課金体系\n料金プランは「データ数課金版」と「容量単価版」という2つのプランからお選びいただけます。",
    "regist-vendor-plan-desc-5":
      "それぞれのプランにおける契約期間は3つの期間(1ヶ月・6ヶ月・１年)からお選びいただけます。\n契約期間がより長いプランほど、月額換算の金額がお安くなります。\n※契約期間内の途中解約はできず、お支払いを継続いただくことが利用条件となります。\nあらかじめご了承ださい。",
    "regist-vendor-plan-desc-6":
      "データ数課金プラン (データ数が小さい場合におすすめ)\n基本料金 + データ数(xCausalで発行する識別コード数)による課金\n5アカウントまで基本サービスに含まれております。\nアカウント数を増やしたい場合には、追加でのご契約が必要です。\n\nデータ数1,000・100項目までの料金体系\n※1 : 1,000名・100項目以上の場合は個別にお問い合わせください。",
    "regist-vendor-plan-desc-7":
      "容量課金プラン(データ数が多い場合におすすめ)\n基本料金(1GBまで) ＋追加データ容量(1GB単位)による課金\n5アカウントまで基本サービスに含まれております。\nアカウント数を増やしたい場合には、追加でのご契約が必要です。",
    "regist-vendor-plan-desc-8":
      "※2 : リファレンスへご協力いただける場合、初年度基本料金を15％ディスカウントします。\n個別にお問い合わせください",

    "regist-vendor-choose": "Continue",
    "regist-vendor-warnings-1":
      "xCausalはお手持ちのデータをアップロードして使えます。",
    "regist-vendor-warnings-2":
      "また、当社のコンディショニングアプリ「you’d」を利用してライフログ取得・連携する事も可能です。",
    "regist-vendor-warnings-3": "その場合の連携フローが下記のようになります。",

    "regist-vendor-alt-1":
      "Connection between individuals・medical care・ cloud",
    "regist-vendor-step": "Step ",
    "regist-vendor-flow-0":
      "クロス・コーザルサービスをご利用する際には、まずWebで必要事項の登録を行いアカウントを開設します。",
    "regist-vendor-flow-1":
      "クロス・コーザル・ポータルサイト上で発行される識別コードを取得し、自社の個人ユーザー(または患者)へ配布いただく準備を行います。入手した識別コードと、メールアドレス等の自社で持っているユーザー情報を紐付けて管理します。",
    "regist-vendor-flow-2":
      "識別コードを自社の個人ユーザーへメールにて配布します。この際、十分なユーザー数と網羅的にデータを確保することがポイントです。解析対象となるデータ項目全てについて記録があるユーザーが、30名以上いることを推奨しています。",
    "regist-vendor-flow-3":
      "識別コードを受け取ったユーザーは、当社のパーソナルコンディショニングアプリyou’d(またはyou’dのOEMアプリ)を通じて、データのやり取りを行うため、まずアプリをダウンロードいただきます。(「you’d」で検索ください。)",
    "regist-vendor-flow-4":
      "識別コードを受け取ったユーザーは、you'd™アプリ上で表示される識別コード入力画面で識別コードを入力し、自分のヘルスデータやライフログの提供と送り元の企業や医療機関にあるデータとの連携について、アプリ画面上で承諾します。",
    "regist-vendor-flow-5":
      "承諾後、you'd™アプリで取得された個人ユーザーのデータは、クラウドにアップロードされます。",
    "regist-vendor-flow-6":
      "自社にあるデータをクロス・コーザル・ポータルサイトのデータ連携ページからcsv形式でアップロードします。",
    "regist-vendor-flow-7":
      "識別コードで連携します。(個人情報はご提供いただかない仕組みです)データ連携後は、匿名化され統計化された状態でクロス・コーザル・サービスをご利用いただきます。ご利用可能なデータは、ユーザーのスマートフォン上のiOS™のヘルスケアや、Android ™版がリリースされた際はGoogleFitにあるヘルスデータ、および「you'd™」でユーザーが入力した体調、不調症状、習慣に関するデータです。ユーザー数が少ない項目に関しては、人数とデータが十分に揃った段階で表示されます。",
    "regist-vendor-flow-9":
      "自社の個人ユーザーや新規個人ユーザーに向けた商品・サービス開発、オンライン診療、IoTサービスなどへご利用いただけます。",
    "regist-vendor-logo-skip": "Upload later",

    PLAN_ID_PLUGIN_1M: "Health data plug-in service (for general businesses)",
    PLAN_ID_PLUGIN_6M: "Health data plug-in service (for general businesses)",
    PLAN_ID_PLUGIN_1Y: "Health data plug-in service (for general businesses)",
    PLAN_ID_MEDICAL_1M:
      "Medical Assist Service (for physicians and medical institutions)",
    PLAN_ID_MEDICAL_6M:
      "Medical Assist Service (for physicians and medical institutions)",
    PLAN_ID_MEDICAL_1Y:
      "Medical Assist Service (for physicians and medical institutions)",
    PLAN_ID_DE_BASIC_1M: "Causal Inference / Basic Version",
    PLAN_ID_DE_BASIC_6M: "Causal Inference / Basic Version",
    PLAN_ID_DE_BASIC_1Y: "Causal Inference / Basic Version",
    PLAN_ID_DE_ADVANCED_1M: "Causal Inference / Advanced Version",
    PLAN_ID_DE_ADVANCED_6M: "Causal Inference / Advanced Version",
    PLAN_ID_DE_ADVANCED_1Y: "Causal Inference / Advanced Version",

    PLAN_ID_CODE_BASIC_1M: " データ数課金プラン ベーシック・バージョン",
    PLAN_ID_CODE_BASIC_6M: " データ数課金プラン ベーシック・バージョン",
    PLAN_ID_CODE_BASIC_12M: " データ数課金プラン ベーシック・バージョン",
    PLAN_ID_CODE_ADVANCED_1M: " データ数課金プラン アドバンス・バージョン",
    PLAN_ID_CODE_ADVANCED_6M: " データ数課金プラン アドバンス・バージョン",
    PLAN_ID_CODE_ADVANCED_12M: " データ数課金プラン アドバンス・バージョン",
    PLAN_ID_CAP_BASIC_1M: " 容量課金プラン ベーシック・バージョン",
    PLAN_ID_CAP_BASIC_6M: " 容量課金プラン ベーシック・バージョン",
    PLAN_ID_CAP_BASIC_12M: " 容量課金プラン ベーシック・バージョン",
    PLAN_ID_CAP_ADVANCED_1M: " 容量課金プラン アドバンス・バージョン",
    PLAN_ID_CAP_ADVANCED_6M: " 容量課金プラン アドバンス・バージョン",
    PLAN_ID_CAP_ADVANCED_12M: " 容量課金プラン アドバンス・バージョン",

    "category-add-title": "Add Group",
    "category-add-name": "Name",
    "category-add-desc": "Description",
    "category-add-sended": "Group added.",

    "corporate-discretization-set": "Open Settings",

    "corporate-health-title": "CSV Upload",
    "corporate-health-desc":
      "Upload the CSV containing the issued identification codes following the steps below:",
    "corporate-health-desc2":
      "　1. Create a CSV with time series data, referring to the sample downloaded from “Sample Download.”\n　2. Download the checker from “CSV Checker Download”, inspect the created CSV and output the inspected CSV (※).\n　3. Upload the inspected CSV via “Upload.”\n　4. If necessary, check the upload summary from “Summary Display.”\n\n※ The CSV checker is for Windows users only. If you are using a different operating system, please refer to the CSV checker manual to create a correctly formatted CSV.",
    "corporate-health-desc3-strong": "Note:",
    "corporate-health-desc3":
      "To use the analysis service, the following two settings must be configured after uploading the CSV:\n\n　1.【Unit, Data Imputation and Application Display Settings】Set each data item and click the “Update Settings” button.\n　2.【Causal Inference Data Settings】Click the “Open Settings” button, confirm the settings, and then click the “Update Settings” button.",

    "corporate-health-csv": "Download\nSample",
    "corporate-health-csv-1": "Download\nCSV Checker",
    "corporate-health-description":
      "Description: A description of health checkup data linkage. \nxxxxxxx xxxxxxxx xxxx\nxxxxxxx xxxxx",
    "corporate-health-file": "Please choose a file",

    "corporate-health-file-title": "Please choose a file",
    "corporate-health-file-select": "Please select an upload method",
    "corporate-health-file-new": "New file /overwrite",
    "corporate-health-file-add": "Add data",
    "corporate-health-upload": "Upload",
    "corporate-health-summary": "View StoreInfo",
    "corporate-health-summary-days": "Period:",
    "corporate-health-summary-users": "Number of user:",
    "corporate-health-summary-records": "Number of records:",
    "corporate-health-summary-records-dist":
      "(※1 day time granularity / imputed missing values)",
    "corporate-health-summary-columns": "Number of items:",
    "corporate-health-summary-last-update": "※Last Upload Date:",
    "corporate-health-show":
      "1.【Unit, Data Imputation and Application Display Settings】",
    "corporate-health-show-key": "Item Name",
    "corporate-health-show-none": "Don't Display",
    "corporate-show-license": "Display Item Settings",
    "corporate-health-show-description-6":
      "Set the unit of measure, unit name, data type, and display in the application for uploaded data items.\n\n※ Click on the “Update Settings” button and proceed to the 2.【Causal Inference Data Settings】.",
    "set-unit": "Set Unit",
    "unit-name": "Unit Name",
    "decimal-places": "Decimal Places",
    "data-type": "Imputation Type",
    "display-in-user-app": "Display in App",
    "vital-data-type": "Linear Interp (continuous data, etc.)",
    "event-data-type": "Zero Imputation (event data, etc.)",
    "update-settings": "Update Settings",

    "session-error-title": "Session Expired",
    "session-error-msg": "Your session has expired. Please login.",

    "error-email-empty": "Please enter your email address.",
    "error-input-empty": "Please complete the form.",

    "error-empty-columns": " must be completed.",

    "app-discret-title": "2.【Causal Inference Data Settings】",
    "app-discret-desc-2":
      "Set the discretization level values first, and if manual, determine the values that bound each level.\nThe causal hierarchy setting only allows causation to the same layer and the layer with the highest number.",
    "app-discret-desc-4":
      "※ Click the “Open Settings” button, confirm the settings, and then click the “Update Settings” button.",

    "app-discret-update-button": "Update Settings",
    "app-discret-selection": "Selection",
    "app-discret-upload": "Data Item",
    "app-discret-levels": "Discretization Levels",
    "app-discret-range": "Discrete Range Setting",
    "app-discret-remove-outliers": "Remove Outliers",
    "app-discret-level-settings": "Causality Hierarchy Setting",
    "app-discret-boundary-values": "Discretization Settings",
    "app-discret-auto": "Automatic",
    "app-discret-manual": "Manual",
    "app-discret-on": "On",
    "app-discret-off": "Off",
    "app-discret-update-display": "Update Display",

    "node-table-ranking-label": "Rank",
    "node-table-item-name": "Item Name",
    "node-table-number-of-people": "Number of people",
    "node-table-number-of-record": "Number of Records",

    "demo-graph-male": "Male",
    "demo-graph-female": "Female",
    "demo-graph-age-group": "Age Group",
    "demo-graph-others": "Others",

    "footer-top": "TOP",
    "footer-faq": "FAQ",
    "footer-inquiry": "Inquiry Form",
    "footer-terms": "Terms of Use",
    "footer-privacy": "Privacy Policy",
    "footer-security": "Information Security Policy",

    "trial-popup-1":
      "You will not be charged during the 1 month free trial period.",
    "trial-popup-2":
      "※It will be automatically renewed after the free trial ends.",

    "error-auth": "Authorization Error. Please login again.",
    "error-within7days": "Data within 7 days cannot be analyzed at the moment.",
    "error-invalidValue": "Invalid Value.",
    "error-exceedMaxValue": "Maximum value exceeded.",
    "error-noOutcomeNode": "Please choose outcome node.",
    "error-noRelevanceNode": "Please choose relevance node.",
    "error-noData":
      "No data found with this set of conditions. Please try with other conditions.",
    "error-exceedMaxNumberOfNodes1": "Maximum of",
    "error-exceedMaxNumberOfNodes2": "nodes is allowed.",
    "error-api-unknown":
      "There was an error loading data from the server. Please try again later.",

    // NEW ERROR
    "error-check-username-and-password":
      "Please check your username or password",
    "communication-error": "Communication Error",
    "error-reenter-code": "Please enter the code again",
    "error-enter-code": "Please enter the code",
    "error-no-service-contract": "No service contract",
    "error-not-enough-items": "Not enough items",
    "error-no-data": "No data",
    "error-datetime-out-of-range": "Datetime data is out of range",
    "error-personal-code-user-not-found":
      "The user associated with the personal code cannot be found",
    "upload-error": "Upload error",
    "ten-items-error": "Up to 10 items can be displayed in the app",
    "error-incorrect-value-entered": "Incorrect value entered",
    "error-no-data-uploaded": "No data uploaded",
    "error-failed-update-settings": "Failed to update settings",
    "error-failed-get-data": "Failed to get data",
    "error-incorrect-value-set": "Incorrect value set",
    "error-check-with-doctor": "Please check with your doctor",
    "error-member-info-not-confirmed":
      "Member information could not be confirmed.",
    "error-select-plan": "Please select a plan",
    "error-logo-size-too-large": "Company logo size too large",
    "error-select-option": "Please select an option plan",
    "error-invalid-additional-account": "Invalid additional account.",

    "無料期間が終了します。": "The free trial will expire soon.",
    "継続して利用しますか？": "Will you  to use the service?",
    継続する: "Yes",
    継続しない: "No",
    スキップ: "skip",
    無料期間: "free trial",
    "（解約）": "(cancel)",
    "（継続）": "(subscribe)",
    期限切れ: "expired",
    は解約されます: "unsubscribe",
    初期に戻す: "Reset",

    // COMMON
    "common-confirm": "Confirm",
    "common-change-name": "Change Name",
    "common-delete": "Delete",
    "common-quarter-day": "1/4 day",
    "common-one-day": "1 day",
    "common-one-week": "1 week",
    "common-one-month": "1 month",
    "common-day": "day",
    "common-week": "week",
    "common-month": "month",

    "common-genders-label": "Gender",
    "common-male-label": "Male",
    "common-female-label": "Female",
    "common-other-label": "Other",
    "common-age-label": "years",
    "common-ages-label": "Age",
    "common-address-label": "Addresses",
    "common-days-label": "Days",
    "common-weeks-label": "Weeks",
    "common-months-label": "Months",
    "common-people-label": "",
    "common-basic-information-label": "Basic Information",
    "common-whole-body-label": "Whole Body",
    "common-whole-body-body-label": "Whole Body",
    "common-condition-label": "Condition",
    "common-worrisome-symptoms-label": "Symptoms",
    "common-location-label": "Location",
    "common-very-good-label": "Great",
    "common-good-label": "Good",
    "common-ok-label": "So-so",
    "common-bad-label": "Bad",
    "common-very-bad-label": "Terrible",

    "common-error-message": "Cannot be displayed due to an error.",

    "not-advanced-message":
      "To use this service, an advanced version contract is required.",
    "not-advanced-message-link": "View Plan Details",
    "not-advanced-skip-button": "Maybe Later",
    "present-condition": "Present Condition",

    // TOP BAR
    "topbar-all-groups-label": "All Groups",
    "topbar-all-addresses-label": "All Regions",
    "topbar-all-ages-label": "All Ages",
    "topbar-no-upper-limit-label": "End",
    "topbar-filtering-label": "Population Filtering",
    "topbar-filter-conditions-label": "Conditions",
    "topbar-number-cases-label": "Cases",
    "topbar-from-label": "from",
    "topbar-to-label": "to",

    ...dataManager,
    ...dashboard,
    ...account,
    ...shared,
    ...graph,
    ...auth,
  },
};

export default en;
