import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDemographic } from "../../../libs/legacy/api";

const initialState = {
  demographicData: [],
  dataArray: [],
  conditions: {},
};

export const getCausalDemographicAsync = createAsyncThunk(
  "graphDispatch/getDemographic",
  async (conditions) => {
    const response = await getDemographic(conditions);
    return response;
  }
);

export const graphDispatchSlice = createSlice({
  name: "graphDispatch",
  initialState,
  reducers: {
    setGraphDispatchDataArray: (state, action) => {
      state.dataArray = action.payload;
    },
    setGraphDispatchConditions: (state, action) => {
      state.conditions = action.payload;
    },
    setGraphDispatchDemographicData: (state, action) => {
      state.demographicData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCausalDemographicAsync.pending, (state) => {})
      .addCase(getCausalDemographicAsync.fulfilled, (state, action) => {
        if (
          action.payload.demographic_section &&
          action.payload.demographic_section.demographic_data
        ) {
          state.demographicData =
            action.payload.demographic_section.demographic_data;
        }
      });
  },
});

export const {
  setGraphDispatchDataArray,
  setGraphDispatchConditions,
  setGraphDispatchDemographicData,
} = graphDispatchSlice.actions;

export const selectGraphDispatchDataArray = (state) =>
  state.graphDispatch.dataArray;
export const selectGraphDispatchConditions = (state) =>
  state.graphDispatch.conditions;

export default graphDispatchSlice.reducer;
