import { UserAdapter } from "../../repository/auth/adapter/user";
import { MFAAdapter } from "../../repository/auth/adapter/mfa";

import { IAuthRepository } from "./irepo";
import { LoginUser } from "./type/login-user";

export class AuthUsecase {
  private repository: IAuthRepository;
  private error: (error: any) => void;

  constructor(repository: IAuthRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  // LOGIN
  async loginAccount(request: any): Promise<any> {
    try {
      const response = await this.repository.loginAccount(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // SETUP
  async setupAccount(request: any): Promise<any> {
    try {
      const response = await this.repository.setupAccount(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async setupVerify(request: any): Promise<any> {
    try {
      const response = await this.repository.setupVerify(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // MFA
  async setupMFA(request: any): Promise<any> {
    try {
      const data = await this.repository.setupMFA(request);
      const response = MFAAdapter.toSetupMFAOutputDTO(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async verifyMFA(request: any): Promise<any> {
    try {
      const data = await this.repository.verifyMFA(request);
      const response = MFAAdapter.toVerifyMFAOutputDTO(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // FORGET
  async resetPassword(request: any): Promise<any> {
    try {
      const response = await this.repository.resetPassword(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async verifyPassword(request: any): Promise<any> {
    try {
      const response = await this.repository.verifyPassword(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // TERMS
  async checkTerms(request: any): Promise<any> {
    try {
      const response = await this.repository.checkTerms(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async verifyTerms(request: any): Promise<any> {
    try {
      const response = await this.repository.verifyTerms(request);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // LOGIN USER
  async fetchLoginUser(request: any): Promise<LoginUser> {
    try {
      const [clientData, userData] = await Promise.all([
        this.repository.fetchClient(request),
        this.repository.fetchUser(request),
      ]);
      const response = UserAdapter.toOutputDTO({ clientData, userData });
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // TRIAL
  async keepTrial(): Promise<any> {
    try {
      const response = await this.repository.keepTrial();
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async skipTrial(): Promise<any> {
    try {
      const response = await this.repository.skipTrial();
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
