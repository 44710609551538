import { formatDate } from "../../../../libs/helper";
import {
  TrendParams,
  TrendEntry,
  DistributionEntry,
  TrendInputDTO,
  TrendOutputDTO,
  TrendResponse,
  TrendResponseEntryData,
  DistributionEntryData,
} from "../../../domain/dashboard/type/trend";

export class TrendAdapter {
  static toInputDTO(request: TrendParams): TrendInputDTO {
    const input_dto: TrendInputDTO = this._convertToInputDTO(request);
    return input_dto;
  }

  static toOutputDTO(response: TrendResponse): TrendOutputDTO {
    const output_dto: TrendOutputDTO = this._convertToOutputDTO(response);
    return output_dto;
  }

  private static _convertToInputDTO = (request: TrendParams): TrendInputDTO => {
    const { metadata, node } = request;
    const response: TrendInputDTO = {
      user_code_list: metadata.data_subject_ids,
      column: node,
      group: metadata.filters.group
        ? metadata.filters.group.map((g) => ({
            group_id: g.id,
            group_name: g.name,
          }))
        : [],
      age: metadata.filters.age,
      gender: metadata.filters.gender,
      start_date: metadata.start_date,
      end_date: metadata.end_date,
    };
    return response;
  };

  private static _convertToOutputDTO = (
    request: TrendResponse
  ): TrendOutputDTO => {
    const trend = {
      age: this._convertToTrend(request.trend.series_with_age, "age"),
      gender: this._convertToTrend(request.trend.series_with_gender, "gender"),
      group: this._convertToTrend(request.trend.series_with_group, "group"),
      config: {
        y_range: request.trend.y_lim,
        y_format: request.trend.y_label_format,
        y_label: request.trend.y_custom_label,
        y_unit: request.trend.y_custom_unit,
      },
    };

    const distribution = {
      age: this._convertToDistribution(
        request.distribution.distribution_with_age,
        "age"
      ),
      gender: this._convertToDistribution(
        request.distribution.distribution_with_gender,
        "gender"
      ),
      group: this._convertToDistribution(
        request.distribution.distribution_with_group,
        "group"
      ),
      config: {
        x_range: request.distribution.x_lim,
        x_format: request.distribution.x_label_format,
        x_label: request.distribution.x_custom_label,
        x_unit: request.distribution.x_custom_unit,
      },
    };
    const response = {
      trend: trend,
      distribution: distribution,
    };
    return response;
  };

  private static _convertToTrend = (
    series: TrendResponseEntryData[],
    key: "age" | "gender" | "group"
  ): TrendEntry[] => {
    const trend: TrendEntry[] = [];

    series.forEach((item) => {
      item.date_list?.forEach((date, index) => {
        const trend_entry = trend.find((entry) => entry.datetime === date);

        if (item[key] !== null && item[key] !== undefined) {
          const key_name = `${item[key]}`;

          if (trend_entry) {
            trend_entry[key_name] = item.series?.[index] ?? null;
          } else {
            const new_entry: TrendEntry = {
              datetime: date,
              [key_name]: item.series?.[index] ?? null,
            };
            trend.push(new_entry);
          }
        }
      });
    });

    trend.sort((a, b) => a.datetime.localeCompare(b.datetime));
    trend.forEach((entry) => {
      entry.datetime = formatDate(entry.datetime, "YYYY/MM/DD");
    });
    return trend;
  };

  private static _convertToDistribution = (
    series: DistributionEntryData[],
    key: "age" | "gender" | "group"
  ): DistributionEntry[] => {
    const distribution: DistributionEntry[] = [];

    series.forEach((item) => {
      item.x?.forEach((xValue, index) => {
        const datetime = xValue.toString();
        const existingEntry = distribution.find(
          (entry) => entry.datetime === datetime
        );
        const keyValue = item[key];

        if (keyValue !== null && keyValue !== undefined) {
          const keyName = `${keyValue}`;

          if (existingEntry) {
            existingEntry[keyName] = item.prob_density?.[index] ?? null;
          } else {
            const newEntry: DistributionEntry = {
              datetime: datetime,
              [keyName]: item.prob_density?.[index] ?? null,
            };
            distribution.push(newEntry);
          }
        }
      });
    });

    distribution.sort((a, b) => a.datetime.localeCompare(b.datetime));
    return distribution;
  };
}
