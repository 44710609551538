import { APIStatus } from "../../../domain/_shared/type/status";

export class StoreAdapter {
  static toStoreProcessStatus(response: any): APIStatus {
    const output_dto: APIStatus = {
      status: response === true ? "success" : "error",
      data: response,
    };
    return output_dto;
  }
}
