import { DemographicReq, Demographic } from "./type/demographic";
import { TrendInputDTO, TrendOutputDTO } from "./type/trend";

import { DemographicAdapter } from "../../repository/dashboard/adapter/demographic";
import { TrendAdapter } from "../../repository/dashboard/adapter/trend";

import { IDashboardRepository } from "./irepo";

export class DashboardUsecase {
  private repository: IDashboardRepository;
  private error: (error: any) => void;

  constructor(repository: IDashboardRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  async getDemographic(request: DemographicReq): Promise<Demographic> {
    try {
      const data = await this.repository.fetchDemographic(request);
      const response = DemographicAdapter.toOutputDTO(data.demographic);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async getTrend(request: TrendInputDTO): Promise<TrendOutputDTO> {
    try {
      const data = await this.repository.fetchTrend(request);
      const response = TrendAdapter.toOutputDTO(data);
      return response;
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
