const graph = {
  "causal-graph-api-error-1": "選択したプロジェクトは存在しません。",
  "causal-graph-api-error-2": "選択したプロジェクトは有効ではありません。",
  "causal-graph-api-error-3":
    "指定したノード定義が存在しないため、他のノードを選択ください。",
  "causal-graph-api-error-4":
    "生成するデータセットに紐づくプロジェクトは存在しません。",
  "causal-graph-api-error-5":
    "生成するデータセットに紐づくプロジェクトは有効ではありません。",
  "causal-graph-api-error-6":
    "データセットのレコードが0件のため、他のノードを選択ください。",
  "causal-graph-api-error-7": "選択したデータセットは存在しません。",
  "causal-graph-api-error-8": "選択したデータセットは有効ではありません。",
  "causal-graph-api-error-9":
    "ノード数が上限に達しているため、ノード数を減らして、再度グラフを生成してください。",
  "causal-graph-api-error-10":
    "生成するモデルに紐づくプロジェクトは存在しません。",
  "causal-graph-api-error-11":
    "生成するモデルに紐づくプロジェクトは有効ではありません。",
  "causal-graph-api-error-12":
    "生成するモデルに紐づくデータセットは存在しません。",
  "causal-graph-api-error-13": "選択したモデルは存在しません。",
  "causal-graph-api-error-14": "選択したモデルは有効ではありません。",
  "causal-graph-api-error-15": "このモデルは保存に失敗したため編集不可です。",
  "causal-graph-api-error-16":
    "現在グラフに表示しているノードと重複しているため、他のノードを追加してください。",
  "causal-graph-api-error-17":
    "ノード数が上限に達しているため、ノード追加不可です。",
  "causal-graph-api-error-18": "このモデルが存在しません。",
  "causal-graph-api-error-19": "このモデルが有効ではありません。",
  "causal-graph-api-error-20":
    "削除しようとするノードがグラフに存在していません。",
  "causal-graph-api-error-21":
    "追加しようとするエッジは禁止されているため、追加不可です。",
  "causal-graph-api-error-22":
    "このエッジを追加するとグラフが巡回するため、他のエッジを追加ください。",
  "causal-graph-api-error-23":
    "ノード数が上限に達しているため、ノード数を減らして、再度エッジを追加してください。",
  "causal-graph-api-error-24":
    "指定したノード定義が存在しないため、他のノードを選択ください。",
  "causal-graph-api-error-25":
    "指定したノードが存在しないため、他のノードを選択ください。",
  "causal-graph-api-error-26":
    "削除しようとするエッジがグラフに存在していません。",
  "causal-graph-api-error-27":
    "このモデルにてレベル数が上限に達しているノードが存在するため、そのノードのレベル数を減らして、再度グラフを生成してください。",
  "causal-graph-api-error-28":
    "指定したエッジルールは禁止されているため、他のエッジを指定してください。",
  "causal-graph-api-error-29":
    "指定した最大親ノード数が有効でないため、設定し直してください。",
  "causal-graph-api-error-30":
    "グラフが巡回するため、このモデルは生成できません。",
  "causal-graph-api-error-31": "このモデルが存在しないため、保存できません。",
  "causal-graph-api-error-32": "このモデルが有効ではないため、保存できません。",
  "causal-graph-api-error-33":
    "このモデルに紐づくプロジェクトは存在しないため、保存できません。",
  "causal-graph-api-error-34":
    "このモデルに紐づくプロジェクトは有効ではないため、保存できません。",
  "causal-graph-api-error-35": "このモデルは既に保存済です。",
  "causal-graph-api-error-36":
    "このモデルが存在しないため、介入操作の一覧を取得できません。",
  "causal-graph-api-error-37":
    "このモデルが有効ではないため、介入操作の一覧を取得できません。",
  "causal-graph-api-error-38":
    "指定した介入変数は、アウトカム変数の子ノードのため、介入操作できません。",
  "causal-graph-api-error-39":
    "このモデルに紐づくプロジェクトが存在しないため、介入操作できません。",
  "causal-graph-api-error-40":
    "このモデルに紐づくプロジェクトが有効ではないため、介入操作できません。",
  "causal-graph-api-error-41":
    "このモデルに紐づくデータセットが存在しないため、介入操作できません。",
  "causal-graph-api-error-42":
    "このモデルに紐づくデータセットが有効ではないため、介入操作できません。",
  "causal-graph-api-error-43":
    "このモデルが存在しないため、介入操作できません。",
  "causal-graph-api-error-44":
    "このモデルが有効ではないため、介入操作できません。",
  "causal-graph-api-error-45":
    "指定した介入変数あるいはアウトカム変数が存在しないため、介入操作できません。",
  "causal-graph-api-error-46":
    "指定した介入変数のレベルは存在しないため、介入操作できません。",
  "causal-graph-api-error-47":
    "このモデルが存在しないため、推定結果の一覧を取得できません。",
  "causal-graph-api-error-48":
    "このモデルが有効ではないため、推定結果の一覧を取得できません。",
  "causal-graph-api-error-49":
    "この推定結果が存在しないため、推定結果を取得できません。",
  "causal-graph-api-error-50":
    "この推定結果が有効ではないため、推定結果を取得できません。",
  "causal-graph-api-error-51":
    "この推定結果が存在しないため、推定結果の名称を変更できません。",
  "causal-graph-api-error-52":
    "この推定結果が有効ではないため、推定結果の名称を変更できません。",
  "causal-graph-api-error-53":
    "この推定結果が存在しないため、推定結果を削除できません。",
  "causal-graph-api-error-54":
    "この推定結果が有効ではないため、推定結果を削除できません。",
  "causal-graph-api-error-55":
    "このモデルに紐づくプロジェクトが存在しないため、推定結果を取得できません。",
  "causal-graph-api-error-56":
    "このモデルに紐づくプロジェクトが有効ではないため、推定結果を取得できません。",
  "causal-graph-api-error-57":
    "このモデルに紐づくデータセットが存在しないため、推定結果を取得できません。",
  "causal-graph-api-error-58":
    "このモデルに紐づくデータセットが有効ではないため、推定結果を取得できません。",
  "causal-graph-api-error-59":
    "このモデルが存在しないため、推定結果を取得できません。",
  "causal-graph-api-error-60":
    "このモデルが有効ではないため、推定結果を取得できません。",

  "causal-graph-population-label": "合計人数",
  "causal-graph-population-percentage-label": "人数比率",
  "causal-graph-title": "因果推論",
  "causal-graph-time-gran-label": "時間粒度の設定：",
  "causal-graph-outcome-node-label": "解析対象とするノードを設定：",
  "causal-graph-outcome-node": "アウトカムノード",
  "causal-graph-treatment-node": "トリートメントノード",
  "causal-graph-relevance-node-label": "関連するノードを設定：",
  "causal-graph-auto-select-label": "による自動選定",
  "causal-graph-mancount-label": "選択したノード全てを有する人数：",
  "causal-graph-mancount-unit": "人",
  "causal-graph-time-lag": "時差（ラグ）",
  "causal-graph-time-lag-label": "時差（ラグ）の設定：",
  "causal-graph-outcome-node-label2":
    "解析対象とするノード\n(アウトカムノード)",
  "causal-graph-relevance-node-label2": "関連ノード",
  "causal-graph-project-name": "プロジェクト：",
  "causal-graph-graph-name": "グラフ：",
  "causal-graph-create-graph-button": "新規グラフ生成開始",
  "causal-graph-load-graph-button": "既存グラフ読み込み",
  "causal-graph-list-project-name": "プロジェクト名",
  "causal-graph-list-create-date": "作成日",
  "causal-graph-list-update-date": "更新日",
  "causal-graph-list-graph-name": "グラフ名",
  "causal-graph-edit-graph-button": "グラフ修正",
  "causal-graph-direct-indirect-button": "直接・間接効果",
  "causal-graph-target-node-selection-button": "対象ノード選択",
  "causal-graph-select-target-node-error": "対象ノードを選んでください。",
  "causal-graph-causal-inference-button": "因果推論",
  "causal-graph-delete-label-edge-button": "エッジ削除",
  "causal-graph-delete-label-node-button": "ノード削除",
  "causal-graph-reverse-label-edge-button": "エッジ反転",
  "causal-graph-add-edge-button": "エッジ追加",
  "causal-graph-add-node-button": "ノード追加",
  "causal-graph-estimate-button": "因果効果の推定",
  "causal-graph-load-interventions-button": "介入操作の読込",
  "causal-graph-create-report-button": "レポートの作成",
  "causal-graph-save-graph-button": "保存",
  "causal-graph-load-graph-short-button": "読込",
  "causal-graph-reset-button": "やり直す",
  "causal-graph-left-click-rotate-label": "左クリック： 回転",
  "causal-graph-mouse-wheel-zoom-label":
    "マウスホイール/ミドルクリック： ズーム",
  "causal-graph-right-click-pan-label": "右クリック： パン",
  "causal-graph-left-click-pan-label": "左クリック： パン",
  "causal-graph-outcome-name-label": "アウトカム名",
  "causal-graph-intervention-variable-label": "介入変数名",
  "causal-graph-di-target-name-label": "媒介変数名",

  "causal-graph-option-switch-label": "因果推論オプション切替",
  "causal-graph-basic-version-label": "ベーシック版",
  "causal-graph-advanced-version-label": "アドバンス版",
  "causal-graph-robustness-label": "頑健性評価",
  "causal-graph-robust-evaluation-button": "頑健性評価を実行",
  "causal-graph-robust-evaluation-1":
    "ATE(連続値基準)の推定結果の変動度合いを頑健性としてテストします。",
  "causal-graph-robust-evaluation-2.1": "以下2つの計算処理により",
  "causal-graph-robust-evaluation-2.2": "頑健性スコア",
  "causal-graph-robust-evaluation-2.3":
    "が0.4以上の場合、頑健性テストを合格したと判定します。",
  "causal-graph-robust-evaluation-3":
    "処理には時間がかかるため、終了時にポップアップで通知されます。",
  "causal-graph-robust-evaluation-random-common":
    "ランダム共通因子検定 (Random common cause)",
  "causal-graph-robust-evaluation-random-detail":
    "共通原因としてアウトカムと介入変数から独立したランダム変数を因果グラフに追加し、推定結果の変動の有無を検証する。",
  "causal-graph-robust-evaluation-data-subset":
    "データ・サブセット検定 (Data Subset)",
  "causal-graph-robust-evaluation-data-subset-detail":
    "データセットからランダムに抽出したサブセットを用いて因果効果を推定し、その推定結果の変動の有無を検証する。",
  "causal-graph-robust-evaluation-complete": "頑健性評価が終了しました。",
  "causal-graph-robust-test-label": "テスト",
  "causal-graph-robust-status-label": "ステータス",
  "causal-graph-robust-p-value-label": "P値",
  "causal-graph-robust-judgement-label": "頑健性検定",
  "causal-graph-robust-message-label": "メッセージ",
  "causal-graph-robust-dash-label": "ー",
  "causal-graph-robust-dots-label": "........................",
  "causal-graph-robust-processing-label": "処理中",
  "causal-graph-robust-finished-label": "頑健性評価が完了しました。",
  "causal-graph-robust-pass-label": "Good",
  "causal-graph-robust-fail-label": "No Good",
  "causal-graph-random-cause-label-1": "ランダム共通因子検定",
  "causal-graph-random-cause-label-2": "(Random common cause)",
  "causal-graph-data-subset-test-label-1": "データ・サブセット検定",
  "causal-graph-data-subset-test-label-2": "(Data Subset)",
  "causal-graph-comprehensive-judgement-label": "総合判定",

  "causal-graph-go-to-results-button": "計算結果の分布表示",
  "causal-graph-expected-difference-label": "介入前後における期待値の差",
  "causal-graph-save-intervention-button": "介入操作の保存",
  "causal-graph-clear-intervention-button": "介入操作のクリア",
  "causal-graph-selected-nodes-label": "選択したノード",
  "causal-graph-selected-edges-label": "選択したエッジ",
  "causal-graph-select-label-intervention-label":
    "介入変数ノードを選択してください",
  "causal-graph-specify-conditions-label": "ノードの条件を指定してください",
  "causal-graph-probability-label": "介入後の確率分布",
  "causal-graph-delete-label": "削除",
  "causal-graph-select-label": "選択",
  "causal-graph-deselect-label": "選択を解除",
  "causal-graph-reverse-label": "反転",
  "causal-graph-confirm-button": "確定",
  "causal-graph-edit-name-button": "名称変更",
  "causal-graph-average-label": "平均処置効果",
  "causal-graph-average-label-advanced": "ATE(連続値基準)",
  "causal-graph-mutual-label": "相互情報量",
  "causal-graph-cross-label": "相関係数",
  "causal-graph-new-effect-label-advanced": "ATE(離散化基準)",
  "causal-graph-time-scale-label": "時間粒度",
  "causal-graph-selection-box-header": "選択",
  "causal-graph-unit-label": "単位",
  "causal-graph-error-select-2-nodes":
    "追加したいエッジとなるノード2つを選択してください。",
  "causal-graph-error-select-1-node": "ノードを1つだけ選択してください。",
  "causal-graph-error-select-outcome-node":
    "アウトカムノードにしたいノードを選択してください。",
  "causal-graph-error-select-treatment-node":
    "トリートメントノードにしたいノードを選択してください。",
  "causal-graph-possible-edges-label": "エッジを追加できるのは下記の通りです。",
  "causal-graph-add-button": "追加",
  "causal-graph-cancel-button": "キャンセル",
  "causal-graph-select-node-text": "追加するノードを選択してください",
  "causal-graph-select-node-label": "追加するノードを選択",
  "causal-graph-node-name-label": "ノード名",
  "causal-graph-submit-button": "決定",
  "causal-graph-enter-name-label": "介入操作名を記入ください",
  "causal-graph-save-graph-label": "保存するグラフ名を記入ください",
  "causal-graph-intervention-label": "介入操作名",
  "causal-graph-date-saved-label": "保存日時日",
  "causal-graph-analysis-date-label": "解析日",
  "causal-graph-intervention-list-label": "介入操作一覧",
  "causal-graph-intervention-saved-label": "介入操作が保存されました",
  "causal-graph-intervention-name-label": "介入名称：",
  "causal-graph-before-intervention-label": "対照群(中央値レベル)",
  "causal-graph-after-intervention-label": "処置群(介入値レベル)",
  "causal-graph-before-intervention-short-label": "対照群(中央値レベル)",
  "causal-graph-after-intervention-short-label": "処置群(介入値レベル)",
  "causal-graph-level-label": "レベル",
  "causal-graph-range-label": "範囲",
  "causal-graph-average-value-label": "平均値",
  "causal-graph-probability-short-label": "確率",
  "causal-graph-intervention-mean-label": "介入平均値",
  "causal-graph-expected-value-label": "期待値",
  "causal-graph-difference-label": "差分",
  "causal-graph-company-name-label": "企業名",
  "causal-graph-project-name-label": "プロジェクト名",
  "causal-graph-model-name-label": "モデル名",
  "causal-graph-conditions-label": "条件",
  "causal-graph-groups-label": "グループ",
  "causal-graph-period-label": "期間",
  "causal-graph-outcome-label": "アウトカム",
  "causal-graph-relevant-node-label": "関連ノード",
  "causal-graph-intervention-operation-label": "介入操作",
  "causal-graph-intervention-level-label": "介入レベル",
  "causal-graph-expected-outcome-label": "アウトカム期待値",
  "causal-graph-difference-before-after-label": "介入前後の差",
  "causal-graph-edge-label": "エッジ",
  "causal-graph-model-label": "モデル",
  "causal-graph-correlation-label": "相互相関",
  "causal-graph-name-label": "グラフ名",
  "causal-graph-item-name-label": "項目名",
  "causal-graph-relevance-rank-label": "関連順位",
  "causal-graph-number-of-records-label": "レコード数",
  "causal-graph-deficit-rate-label": "欠損率",
  "causal-graph-report-type-label": "レポート生成方法を選択",
  "causal-graph-create-button": "作成",
  "causal-graph-estimation-results-label": "推定結果を表示する",
  "causal-graph-compare-estimation-label": "推定結果を比較表示する",
  "causal-graph-view-report-label": "レポートを表示する",
  "causal-graph-print-label": "印刷",
  "causal-graph-close-label": "閉じる",
  "causal-graph-graph-model-label": "グラフモデル",
  "causal-graph-edge-ATE-label": "エッジの値: ATE",
  "causal-graph-before-direct-label": "直接因果効果推定前",
  "causal-graph-after-direct-label": "直接因果効果推定後",
  "causal-graph-pre-probability-label": "介入前確率",
  "causal-graph-post-probability-label": "介入後確率",
  "causal-graph-distribution-before-after-label": "介入前後の分布表",
  "causal-graph-relationship-table-label": "関係表",
  "causal-graph-reference-information-label": "関係表",
  "causal-graph-data-information-label": "データ情報",
  "causal-graph-edge-parameters-label": "[開発用]エッジパラメータ",
  "causal-graph-update-model-button": "モデル更新",
  "causal-graph-probabilty-before-after-label": "介入前後の確率",
  "causal-graph-before-short-label": "前",
  "causal-graph-after-short-label": "後",
  "causal-graph-enter-number-label": "数字を入力してください",
  "causal-graph-cannot-delete-objective-label":
    "アウトカムノードはノード削除できません。",
  "causal-graph-must-select-node-label": "ノードを選択する必要があります",
  "causal-graph-must-select-edge-label": "エッジを選択する必要があります",
  "causal-graph-intervention-effect-label": "介入効果",
  "causal-graph-weeks-label": "週",
  "causal-graph-months-label": "月",
  "causal-graph-outcome-node-button1": "アウトカム",
  "causal-graph-outcome-node-button2": "ノード変更",
  "causal-graph-treatment-node-button1": "トリートメント",
  "causal-graph-treatment-node-button2": "ノード変更",
  "causal-graph-cannot-reverse-edge": "エッジ反転はできません。",

  "causal-graph-looped-graph-error":
    "※エッジにループ（巡回）が発生するため追加不可",
  "causal-graph-too-large-target-error":
    "※ラグで設定された時系列と矛盾するため追加不可（未来→過去）",
  "causal-graph-too-large objective-error":
    "※アウトカムノードのラグが関連ノードのラグよりも大きいため追加不可",
  "causal-graph-column-not-found-error": "※指定ノードが見つかりません",
  "causal-graph-not-available-label": "※禁止ルールにより追加不可",

  "causal-graph-looped-graph-error-reverse":
    "エッジにループ（巡回）が発生するため、エッジ反転はできません。",
  "causal-graph-too-large-target-error-reverse":
    "ラグで設定された時系列と矛盾するため、エッジ反転はできません。",
  "causal-graph-too-large objective-error-reverse":
    "ラグで設定された時系列と矛盾するため、エッジ反転はできません。",
  "causal-graph-column-not-found-error-reverse":
    "指定ノードが見つからないため、エッジ反転はできません。",
  "causal-graph-not-available-label-reverse":
    "禁止ルールにより、エッジ反転はできません。",

  "causal-graph-distribution-y-axis-label": "アウトカムノードの確率分布",
  "causal-graph-histogram-y-axis-label": "アウトカムノードの確率",
  "causal-graph-distribution-y-axis-label-treatment":
    "トリートメントノードの確率分布",
  "causal-graph-histogram-y-axis-label-treatment": "トリートメントノードの確率",

  "causal-graph-probability-dist-label": "確率分布",
  "causal-graph-center-label": "中央",
  "causal-graph-load-existing-graph-label": "(既存グラフ読み込み)",
  "causal-graph-check-nodes-label": "ノード決定",
  "causal-graph-focus": "焦点",

  "causal-graph-treament-mode": "トリートメントモード",
  "causal-graph-treament-node": "トリートメントノード",
  "causal-graph-target-node": "アウトカム名",
  "causal-graph-source-node": "介入変数名",
  "causal-graph-mediator-node": "媒介変数名",
  "causal-graph-choose-target": "グラフでアウトカムノードを選択してください",
  "causal-graph-choose-mediator": "介入変数と媒介変数ノードを選択してください",
  "causal-graph-no-mediators-available": "媒介変数ノードがありません",
  "causal-graph-overall-causal-effect": "総合因果効果",
  "causal-graph-direct-effect": "直接効果",
  "causal-graph-indirect-effect": "間接効果",
  "causal-graph-robustness-fail-missing-confounders-message":
    "交絡因子が不足している可能性があります",
  "causal-graph-robustness-fail-low-data-message":
    "データ数が不足している可能性があります",
  "causal-graph-robustness-pass-message": "頑健性スコアが良い値を示しています",
  "causal-graph-expected-diff": "期待値の差",
  "causal-graph-journal-search-button": "調べる",
  "causal-graph-journal-next-button": "追加表示",
  "causal-graph-search-1": "論文検索",
  "causal-graph-search-2":
    "関連する論文をSemantic Scholarの論文データベースから簡易検索することが可能です。",
  "causal-graph-hover-node-message":
    "ノードの選択は、右クリック、もしくはダブルクリックをしてください。",
  "causal-graph-hover-edge-message":
    "エッジの選択は、右クリック、もしくはダブルクリックをしてください。",
  "causal-graph-rename-title": "グラフ名を入力してください。",

  "columns-user-error-message":
    "「選択したノード全てを有する人数」が0人です。1人以上になるように関連するノードを設定してください",

  "strat-button": "層別化設定",
  "strat-settings-label": "層別化する変数を設定：",
  "strat-node": "層別化変数",
  "strat-group": "層別グループ",
  "strat-range": "層別範囲",
  "strat-layout-button": "層別因果推論",
  "number-of-records": "データ数",
  "causal-graph-distribution-y-axis-label-strat": "層別化変数の確率分布",
  "strat-min-max-message1": "までの値を選択してください",
  "strat-min-max-message2": "から",
  "strat-table-title1": "各層毎で",
  "strat-table-title2": "に介入した場合の",

  "choose-algorithm-button": "詳細設定はこちら",
  "choose-algorithm-text": "因果探索アルゴリズムを選択してください",
  "hillclimb-algo": "ヒルクライム探索",
  "pc-algo": "PCアルゴリズム",
  "mmhc-algo": "MMHCアルゴリズム",

  "hillclimb-info-title": "ヒルクライム探索(BDeU)",
  "hillclimb-info-text":
    "データへの適合度が高くなるように因果グラフを逐次的に探索します。データに適合するグラフを効率的に発見できる一方、近似解法の一種であり局所最適解に陥る可能性があります。",
  "pc-info-title": "PC(Peter-Clark)アルゴリズム",
  "pc-info-text":
    "データを用いて条件付き独立性の判定を行いながら、因果グラフ構造を推定します。統計的な信頼性に基づいて依存関係を推定できる一方、一定のルールに基づきエッジの方向を定めるため、因果の方向を正しく推定できない可能性があります。また、計算コストは一般的にヒルクライム法より高くなります。",
  "mmhc-info-title": "MMHC(Max-Min Hill-Climbing)アルゴリズム",
  "mmhc-info-text":
    "PC法で得られた依存関係を利用して、ヒルクライム法によって因果グラフの構造を推定します。統計的な依存関係とデータへの適合度に基づいて構造推定できる一方、計算コストは最も高くなります。",
};

export default graph;
