import { APIStatus } from "../../../domain/_shared/type/status";
import { EditNodeRequest, Node } from "../../../domain/data-manager/type/node";

export class NodeAdapter {
  static toEditNodeInputDTO(request: EditNodeRequest): Node[] {
    const input_dto: Node[] = request.map((e) => ({
      id: e.id,
      name: e.name,
      data_range: e.data_range ?? null,
      unit: e.unit ?? null,
      regexp: e.regexp ?? null,
      decimal: e.decimal ?? 0,
      status: e.status ?? 0,
      is_auto_discretization: e.is_auto_discretization,
      is_outlier_rejection: e.is_outlier_rejection,
      is_continuous: e.is_continuous,
      is_ordered: e.is_ordered,
      is_display_on_app: e.is_display_on_app ?? false,
      auto_discretization_type: e.auto_discretization_type,
      feature_scaling_type: e.feature_scaling_type,
      imputation_type: e.imputation_type,
      discretization:
        e.discretization?.map((d) => ({
          ...d,
          min: d.min ?? null,
          max: d.max ?? null,
        })) ?? [],
      edge_rule: {
        level: e.edge_rule.level ?? 2,
        color: e.edge_rule.color ?? "#ffffff",
      },
      node_property_updated_at: e.node_property_updated_at ?? null,
      discretization_updated_at: e.discretization_updated_at ?? null,
    }));
    return input_dto;
  }

  static toNodeOutputDTO(response: any): Node[] {
    const output_dto: Node[] = response.map((e: any) => {
      return {
        id: e.id,
        name: e.name,
        data_range: e.data_range ?? null,
        unit: e.unit ?? null,
        regexp: e.regexp ?? null,
        decimal: e.decimal ?? 0,
        status: e.status ?? 0,
        is_auto_discretization: e.is_auto_discretization,
        is_outlier_rejection: e.is_outlier_rejection,
        is_continuous: e.is_continuous,
        is_ordered: e.is_ordered,
        is_display_on_app: e.is_display_on_app ?? false,
        auto_discretization_type: e.auto_discretization_type,
        feature_scaling_type: e.feature_scaling_type,
        imputation_type: e.imputation_type,
        discretization:
          e.discretization?.map((d: any) => ({
            ...d,
            min: d.min ?? null,
            max: d.max ?? null,
          })) ?? [],
        edge_rule: {
          level: e.edge_rule.level ?? 2,
          color: e.edge_rule.color ?? "#ffffff",
        },
        node_property_updated_at: e.node_property_updated_at ?? null,
        discretization_updated_at: e.discretization_updated_at ?? null,
      };
    });
    return output_dto;
  }

  static toNodeStatus(response: any): APIStatus {
    const output_dto: APIStatus = {
      status: response.status,
      data: response.data,
    };
    return output_dto;
  }
}
