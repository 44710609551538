import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAnalyses,
  getBNModel,
  getDataset,
  getDoReport,
  getJournalSearch,
  getModels,
  reportPublish,
  reportPublishCompare,
  checkMediator,
  computeMediator,
  getClientInfo,
} from "../../../libs/legacy/api";
import {
  getSubgroupLabel,
  getDistribution,
  getHistogram,
} from "../../../libs/legacy/api";
import i18n from "../../../i18n";

const initialState = {
  datasetData: null,
  BNModelData: null,
  causalInferenceData: null,
  causalInferenceData1: null,
  causalInferenceData2: null,
  analyses: null,
  models: null,
  reports: null,
  analysis1: null,
  analysis2: null,
  model1: null,
  model2: null,
  report1: null,
  report2: null,
  chartData: null,
  chartData1: null,
  chartData2: null,
  publishData1: null,
  publishColumnProperties1: null,
  publishData2: null,
  publishColumnProperties2: null,
  distributionData: null,
  histogramData: null,
  outcomeNode: null,
  diOutcomeNode: null,
  timeUnit: null,
  variableType: null,
  relevanceColumnsData: null,
  shouldLoadOutcomeNodeGraph: false,
  isSavedReport: false,
  canCreateGraph: false,
  dwSelectionOn: false,
  diSelectedNodes: [],
  graphData: null,
  advancedVersion: false,
  model: "bnmodel",
  showRobustnessResult: false,
  canSelectThreshold: false,
  mediators: null,
  nodeInfoSpinning: false,
  computeMediatorData: null,
  journalSearchSpinning: false,
  journalSearchData1: null,
  journalSearchData2: null,
  isPastLook: true,
  hoverMessageText: "",
  graphIsUpdating: false,

  stratificationOn: false,
  stratificationFuncOn: false,
  shouldUpdateStrat: false,
  stratifications: [],
  stratificationLabels: [],
  stratificationLabel: "",
  stratificationGroupA: "A",
  stratificationGroupB: "B",
  stratificationGroupC: "C",
  stratificationValue1: null,
  stratificationValue2: null,
  stratDistributionData: null,
  stratificationSubgroup: null,
  stratificationChartData: null,
};

export const fetchClientInfoAsync = createAsyncThunk(
  "causalGraph/fetchClientInfo",
  async () => {
    const response = await getClientInfo();
    return response;
  }
);

export const fetchDatasetAsync = createAsyncThunk(
  "causalGraph/fetchDataset",
  async (conditions) => {
    const response = await getDataset(conditions);
    return response;
  }
);

export const fetchBNModelAsync = createAsyncThunk(
  "causalGraph/fetchBNModel",
  async (conditions) => {
    const response = await getBNModel(conditions);
    return response;
  }
);

export const fetchAnalysesAsync = createAsyncThunk(
  "causalGraph/fetchAnalyses",
  async (conditions) => {
    const response = await getAnalyses(conditions);
    return response;
  }
);

export const fetchModelsAsync = createAsyncThunk(
  "causalGraph/fetchModels",
  async (conditions) => {
    const response = await getModels(conditions);
    return response;
  }
);

export const fetchReportsAsync = createAsyncThunk(
  "causalGraph/fetchReports",
  async (conditions) => {
    const response = await getDoReport(conditions);
    return response;
  }
);

export const fetchDistributionDataAsync = createAsyncThunk(
  "causalGraph/fetchDistribution",
  async (conditions) => {
    const response = await getDistribution(conditions);
    return response;
  }
);

export const fetchHistogramDataAsync = createAsyncThunk(
  "causalGraph/fetchHistogram",
  async (conditions) => {
    const response = await getHistogram(conditions);
    return response;
  }
);

export const fetchReportPublishAsync = createAsyncThunk(
  "causalGraph/fetchReportPublish",
  async (conditions) => {
    const response = await reportPublish(conditions);
    return response;
  }
);

export const fetchReportPublishCompareAsync = createAsyncThunk(
  "causalGraph/fetchReportPublishCompare",
  async (conditions) => {
    const response = await reportPublishCompare(conditions);
    return response;
  }
);

export const fetchCheckMediatorAsync = createAsyncThunk(
  "causalGraph/fetchCheckMediator",
  async (conditions) => {
    const response = await checkMediator(conditions);
    return response;
  }
);

export const fetchComputeMediatorDataAsync = createAsyncThunk(
  "causalGraph/fetchComputeMediator",
  async (conditions) => {
    const response = await computeMediator(conditions);
    return response;
  }
);

export const fetchJournalSearchDataAsync = createAsyncThunk(
  "causalGraph/fetchJournalSearch",
  async (conditions) => {
    const response = await getJournalSearch(conditions);
    return response;
  }
);

export const fetchStratDistributionDataAsync = createAsyncThunk(
  "causalGraph/fetchStratDistribution",
  async (conditions) => {
    const response = await getDistribution(conditions);
    return response;
  }
);

export const fetchStratificationLabelsAsync = createAsyncThunk(
  "causalGraph/fetchStratificationLabels",
  async (conditions) => {
    const response = await getSubgroupLabel(conditions);
    return response;
  }
);

export const causalGraphSlice = createSlice({
  name: "causalGraph",
  initialState,
  reducers: {
    setDatasetData: (state, action) => {
      state.datasetData = action.payload;
    },
    setBNModelData: (state, action) => {
      state.BNModelData = action.payload;
    },
    setCausalInferenceData: (state, action) => {
      state.causalInferenceData = action.payload;
    },
    setCausalInferenceData1: (state, action) => {
      state.causalInferenceData1 = action.payload;
    },
    setCausalInferenceData2: (state, action) => {
      state.causalInferenceData2 = action.payload;
    },
    setAnalysis1: (state, action) => {
      state.analysis1 = action.payload;
    },
    setAnalysis2: (state, action) => {
      state.analysis2 = action.payload;
    },
    setModel1: (state, action) => {
      state.model1 = action.payload;
    },
    setModel2: (state, action) => {
      state.model2 = action.payload;
    },
    setReport1: (state, action) => {
      state.report1 = action.payload;
    },
    setReport2: (state, action) => {
      state.report2 = action.payload;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setChartData1: (state, action) => {
      state.chartData1 = action.payload;
    },
    setChartData2: (state, action) => {
      state.chartData2 = action.payload;
    },
    setPublishData1: (state, action) => {
      state.publishData1 = action.payload;
    },
    setPublishColumnProperties1: (state, action) => {
      state.publishColumnProperties1 = action.payload;
    },
    setPublishData2: (state, action) => {
      state.publishData2 = action.payload;
    },
    setPublishColumnProperties2: (state, action) => {
      state.publishColumnProperties2 = action.payload;
    },
    setOutcomeNode: (state, action) => {
      state.outcomeNode = action.payload;
    },
    setDIOutcomeNode: (state, action) => {
      state.diOutcomeNode = action.payload;
    },
    setDistributionData: (state, action) => {
      state.distributionData = action.payload;
      state.shouldLoadOutcomeNodeGraph = true;
    },
    setHistogramData: (state, action) => {
      state.histogramData = action.payload;
      state.shouldLoadOutcomeNodeGraph = true;
    },
    setTimeUnit: (state, action) => {
      state.timeUnit = action.payload;
    },
    setVariableType: (state, action) => {
      state.variableType = action.payload;
    },
    setRelevanceColumnsData: (state, action) => {
      state.relevanceColumnsData = action.payload;
    },
    setShouldLoadOutcomeNodeGraph: (state, action) => {
      state.shouldLoadOutcomeNodeGraph = action.payload;
    },
    setIsSavedReport: (state, action) => {
      state.isSavedReport = action.payload;
    },
    setCanCreateGraph: (state, action) => {
      state.canCreateGraph = action.payload;
    },
    setDWSelectionOn: (state, action) => {
      state.dwSelectionOn = action.payload;
    },
    setDISelectedNodes: (state, action) => {
      state.diSelectedNodes = action.payload;
    },
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    setAdvancedVersion: (state, action) => {
      let isAdvanced = action.payload;
      state.advancedVersion = action.payload;
      if (isAdvanced) {
        state.model = "dwmodel";
      } else {
        state.model = "bnmodel";
      }
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    setShowRobustnessResult: (state, action) => {
      state.showRobustnessResult = action.payload;
    },
    setCanSelectThreshold: (state, action) => {
      state.canSelectThreshold = action.payload;
    },
    setMediators: (state, action) => {
      state.mediators = action.payload;
    },
    setNodeInfoSpinning: (state, action) => {
      state.nodeInfoSpinning = action.payload;
    },
    setComputeMediatorData: (state, action) => {
      state.computeMediatorData = action.payload;
    },
    setJournalSearchSpinning: (state, action) => {
      state.journalSearchSpinning = action.payload;
    },
    setJournalSearchData1: (state, action) => {
      state.journalSearchData1 = action.payload;
    },
    setJournalSearchData2: (state, action) => {
      state.journalSearchData2 = action.payload;
    },
    setIsPastLook: (state, action) => {
      state.isPastLook = action.payload;
    },
    setHoverMessageText: (state, action) => {
      state.hoverMessageText = action.payload;
    },
    setGraphIsUpdating: (state, action) => {
      state.graphIsUpdating = action.payload;
    },
    setStratifications: (state, action) => {
      state.stratifications = action.payload;
    },
    setStratificationLabels: (state, action) => {
      state.stratificationLabels = action.payload;
    },
    setStratificationOn: (state, action) => {
      state.stratificationOn = action.payload;
    },
    setStratificationFuncOn: (state, action) => {
      state.stratificationFuncOn = action.payload;
    },
    setShouldUpdateStrat: (state, action) => {
      state.shouldUpdateStrat = action.payload;
    },
    setStratificationLabel: (state, action) => {
      state.stratificationLabel = action.payload;
    },
    setStratificationGroupA: (state, action) => {
      state.stratificationGroupA = action.payload;
    },
    setStratificationGroupB: (state, action) => {
      state.stratificationGroupB = action.payload;
    },
    setStratificationGroupC: (state, action) => {
      state.stratificationGroupC = action.payload;
    },
    setStratificationValue1: (state, action) => {
      state.stratificationValue1 = action.payload;
    },
    setStratificationValue2: (state, action) => {
      state.stratificationValue2 = action.payload;
    },
    setStratDistributionData: (state, action) => {
      state.stratDistributionData = action.payload;
    },
    setStratificationSubgroup: (state, action) => {
      state.stratificationSubgroup = action.payload;
    },
    setStratificationChartData: (state, action) => {
      state.stratificationChartData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchClientInfoAsync.pending, (state) => {})
      // .addCase(fetchClientInfoAsync.fulfilled, (state, action) => {
      //   if (action.payload) {
      //     const data = action.payload;
      //     const isAdvanced = data.is_advance;
      //     state.advancedVersion = isAdvanced;
      //     if (isAdvanced) {
      //       state.model = "dwmodel";
      //     } else {
      //       state.model = "bnmodel";
      //     }
      //   }
      // })
      .addCase(fetchDatasetAsync.pending, (state) => {})
      .addCase(fetchDatasetAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const data = action.payload;
          const columnsObject = {};
          const columnsLabelObject = {};

          for (let item of data.column_properties) {
            columnsObject[item.value] = item;
            columnsLabelObject[item.name] = item;
          }

          const datasetData = {
            columns: columnsObject,
            columnsByLabel: columnsLabelObject,
          };
          state.datasetData = datasetData;
        } else {
          state.datasetData = null;
        }
      })
      .addCase(fetchBNModelAsync.pending, (state) => {})
      .addCase(fetchBNModelAsync.fulfilled, (state, action) => {
        state.BNModelData = action.payload;
      })
      .addCase(fetchAnalysesAsync.pending, (state) => {})
      .addCase(fetchAnalysesAsync.fulfilled, (state, action) => {
        state.analyses = action.payload;
        if (action.payload.analyses.length > 0) {
          state.analysis1 = action.payload.analyses[0];
          state.analysis2 = action.payload.analyses[0];
        }
      })
      .addCase(fetchModelsAsync.pending, (state) => {})
      .addCase(fetchModelsAsync.fulfilled, (state, action) => {
        state.models = action.payload;
      })
      .addCase(fetchReportsAsync.pending, (state) => {})
      .addCase(fetchReportsAsync.fulfilled, (state, action) => {
        state.reports = action.payload;
      })
      .addCase(fetchDistributionDataAsync.pending, (state) => {})
      .addCase(fetchDistributionDataAsync.fulfilled, (state, action) => {
        state.distributionData = action.payload;
      })
      .addCase(fetchHistogramDataAsync.pending, (state) => {})
      .addCase(fetchHistogramDataAsync.fulfilled, (state, action) => {
        state.histogramData = action.payload;
      })
      .addCase(fetchReportPublishAsync.pending, (state) => {})
      .addCase(fetchReportPublishAsync.fulfilled, (state, action) => {
        let data = null;
        if (action.payload) {
          data = action.payload;
          state.publishData1 = data;
          if (
            data[`${state.model}_report_publishment_1`] &&
            data[`${state.model}_report_publishment_1`].dataset_metadata &&
            data[`${state.model}_report_publishment_1`].dataset_metadata
              .column_properties
          ) {
            const columnProperties =
              data[`${state.model}_report_publishment_1`].dataset_metadata
                .column_properties;
            const columnsObject = {};
            const columnsLabelObject = {};

            for (let item of columnProperties) {
              columnsObject[item.value] = item;
              columnsLabelObject[item.name] = item;
            }

            const publishColumnProperties1 = {
              columns: columnsObject,
              columnsByLabel: columnsLabelObject,
            };
            state.publishColumnProperties1 = publishColumnProperties1;
          }
        }
      })
      .addCase(fetchReportPublishCompareAsync.pending, (state) => {})
      .addCase(fetchReportPublishCompareAsync.fulfilled, (state, action) => {
        let data = null;
        if (action.payload) {
          data = action.payload;
          state.publishData2 = data;
          if (
            data[`${state.model}_report_publishment_1`] &&
            data[`${state.model}_report_publishment_1`].dataset_metadata &&
            data[`${state.model}_report_publishment_1`].dataset_metadata
              .column_properties
          ) {
            const columnProperties =
              data[`${state.model}_report_publishment_1`].dataset_metadata
                .column_properties;
            const columnsObject = {};
            const columnsLabelObject = {};

            for (let item of columnProperties) {
              columnsObject[item.value] = item;
              columnsLabelObject[item.name] = item;
            }

            const publishColumnProperties1 = {
              columns: columnsObject,
              columnsByLabel: columnsLabelObject,
            };
            state.publishColumnProperties1 = publishColumnProperties1;
          }
          if (
            data[`${state.model}_report_publishment_2`] &&
            data[`${state.model}_report_publishment_2`].dataset_metadata &&
            data[`${state.model}_report_publishment_2`].dataset_metadata
              .column_properties
          ) {
            const columnProperties =
              data[`${state.model}_report_publishment_2`].dataset_metadata
                .column_properties;
            const columnsObject = {};
            const columnsLabelObject = {};

            for (let item of columnProperties) {
              columnsObject[item.value] = item;
              columnsLabelObject[item.name] = item;
            }

            const publishColumnProperties2 = {
              columns: columnsObject,
              columnsByLabel: columnsLabelObject,
            };
            state.publishColumnProperties2 = publishColumnProperties2;
          }
        }
      })
      .addCase(fetchCheckMediatorAsync.pending, (state) => {
        state.nodeInfoSpinning = true;
      })
      .addCase(fetchCheckMediatorAsync.fulfilled, (state, action) => {
        if (action.payload && action.payload.available_mediator_source_list) {
          state.mediators = action.payload.available_mediator_source_list;
          state.nodeInfoSpinning = false;
        } else {
          state.nodeInfoSpinning = false;
          state.mediators = null;
        }
      })
      .addCase(fetchComputeMediatorDataAsync.pending, (state) => {
        state.nodeInfoSpinning = true;
      })
      .addCase(fetchComputeMediatorDataAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.computeMediatorData = action.payload;
          state.nodeInfoSpinning = false;
        } else {
          state.nodeInfoSpinning = false;
          state.computeMediatorData = null;
        }
      })
      .addCase(fetchJournalSearchDataAsync.pending, (state) => {
        state.journalSearchSpinning = true;
      })
      .addCase(fetchJournalSearchDataAsync.fulfilled, (state, action) => {
        if (action.payload && action.payload) {
          state.journalSearchData1 = action.payload;
          state.journalSearchSpinning = false;
        } else {
          state.journalSearchSpinning = false;
          if (action.payload && action.payload.detail) {
            state.journalSearchData1 = action.payload;
          } else {
            state.journalSearchData1 = { detail: i18n.t("error-api-unknown") };
          }
        }
      })

      .addCase(fetchStratificationLabelsAsync.pending, (state) => {})
      .addCase(fetchStratificationLabelsAsync.fulfilled, (state, action) => {
        if (action.payload && action.payload.labels) {
          state.stratificationLabels = action.payload.labels;
        }
      })
      .addCase(fetchStratDistributionDataAsync.pending, (state) => {})
      .addCase(fetchStratDistributionDataAsync.fulfilled, (state, action) => {
        state.stratDistributionData = action.payload;
      });
  },
});

export const {
  setDatasetData,
  setBNModelData,
  setCausalInferenceData,
  setCausalInferenceData1,
  setCausalInferenceData2,
  setAnalysis1,
  setAnalysis2,
  setModel1,
  setModel2,
  setReport1,
  setReport2,
  setChartData,
  setChartData1,
  setChartData2,
  setPublishData1,
  setPublishColumnProperties1,
  setPublishData2,
  setPublishColumnProperties2,
  setOutcomeNode,
  setDIOutcomeNode,
  setDistributionData,
  setHistogramData,
  setTimeUnit,
  setVariableType,
  setRelevanceColumnsData,
  setShouldLoadOutcomeNodeGraph,
  setIsSavedReport,
  setCanCreateGraph,
  setDWSelectionOn,
  setDISelectedNodes,
  setGraphData,
  setAdvancedVersion,
  setModel,
  setShowRobustnessResult,
  setCanSelectThreshold,
  setMediators,
  setNodeInfoSpinning,
  setComputeMediatorData,
  setJournalSearchSpinning,
  setJournalSearchData1,
  setJournalSearchData2,
  setIsPastLook,
  setHoverMessageText,
  setGraphIsUpdating,

  setStratificationOn,
  setStratificationFuncOn,
  setShouldUpdateStrat,
  setStratificationLabel,
  setStratificationGroupA,
  setStratificationGroupB,
  setStratificationGroupC,
  setStratificationValue1,
  setStratificationValue2,
  setStratifications,
  setStratificationLabels,
  setStratDistributionData,
  setStratificationSubgroup,
  setStratificationChartData,
} = causalGraphSlice.actions;

export const selectDatasetData = (state) => state.causalGraph.datasetData;
export const selectBNModelData = (state) => state.causalGraph.BNModelData;
export const selectCausalInferenceData = (state) =>
  state.causalGraph.causalInferenceData;
export const selectCausalInferenceData1 = (state) =>
  state.causalGraph.causalInferenceData1;
export const selectCausalInferenceData2 = (state) =>
  state.causalGraph.causalInferenceData2;
export const selectAnalyses = (state) => state.causalGraph.analyses;
export const selectModels = (state) => state.causalGraph.models;
export const selectReports = (state) => state.causalGraph.reports;
export const selectAnalysis1 = (state) => state.causalGraph.analysis1;
export const selectAnalysis2 = (state) => state.causalGraph.analysis2;
export const selectModel1 = (state) => state.causalGraph.model1;
export const selectModel2 = (state) => state.causalGraph.model2;
export const selectReport1 = (state) => state.causalGraph.report1;
export const selectReport2 = (state) => state.causalGraph.report2;
export const selectChartData = (state) => state.causalGraph.chartData;
export const selectChartData1 = (state) => state.causalGraph.chartData1;
export const selectChartData2 = (state) => state.causalGraph.chartData2;
export const selectPublishData1 = (state) => state.causalGraph.publishData1;
export const selectPublishColumnProperties1 = (state) =>
  state.causalGraph.publishColumnProperties1;
export const selectPublishData2 = (state) => state.causalGraph.publishData2;
export const selectPublishColumnProperties2 = (state) =>
  state.causalGraph.publishColumnProperties2;
export const selectDistributionData = (state) =>
  state.causalGraph.distributionData;
export const selectHistogramData = (state) => state.causalGraph.histogramData;
export const selectOutcomeNode = (state) => state.causalGraph.outcomeNode;
export const selectDIOutcomeNode = (state) => state.causalGraph.diOutcomeNode;
export const selectTimeUnit = (state) => state.causalGraph.timeUnit;
export const selectVariableType = (state) => state.causalGraph.variableType;
export const selectRelevanceColumnsData = (state) =>
  state.causalGraph.relevanceColumnsData;
export const selectShouldLoadOutcomeNodeGraph = (state) =>
  state.causalGraph.shouldLoadOutcomeNodeGraph;
export const selectIsSavedReport = (state) => state.causalGraph.isSavedReport;
export const selectCanCreateGraph = (state) => state.causalGraph.canCreateGraph;
export const selectDISelectionOn = (state) => state.causalGraph.dwSelectionOn;
export const selectDWTargetNodes = (state) => state.causalGraph.diSelectedNodes;
export const selectGraphData = (state) => state.causalGraph.graphData;
export const selectAdvancedVersion = (state) =>
  state.causalGraph.advancedVersion;
export const selectModel = (state) => state.causalGraph.model;
export const selectShowRobustnessResult = (state) =>
  state.causalGraph.showRobustnessResult;
export const selectCanSelectThreshold = (state) =>
  state.causalGraph.canSelectThreshold;
export const selectMediators = (state) => state.causalGraph.mediators;
export const selectNodeInfoSpinning = (state) =>
  state.causalGraph.nodeInfoSpinning;
export const selectComputeMediatorData = (state) =>
  state.causalGraph.computeMediatorData;
export const selectJournalSearchSpinning = (state) =>
  state.causalGraph.journalSearchSpinning;
export const selectJournalSearchData1 = (state) =>
  state.causalGraph.journalSearchData1;
export const selectJournalSearchData2 = (state) =>
  state.causalGraph.journalSearchData2;
export const selectIsPastLook = (state) => state.causalGraph.isPastLook;
export const selectHoverMessageText = (state) =>
  state.causalGraph.hoverMessageText;
export const selectGraphIsUpdating = (state) =>
  state.causalGraph.graphIsUpdating;

export const selectStratificationOn = (state) =>
  state.causalGraph.stratificationOn;
export const selectStratificationFuncOn = (state) =>
  state.causalGraph.stratificationFuncOn;
export const selectShouldUpdateStrat = (state) =>
  state.causalGraph.shouldUpdateStrat;
export const selectStratificationLabel = (state) =>
  state.causalGraph.stratificationLabel;
export const selectStratificationGroupA = (state) =>
  state.causalGraph.stratificationGroupA;
export const selectStratificationGroupB = (state) =>
  state.causalGraph.stratificationGroupB;
export const selectStratificationGroupC = (state) =>
  state.causalGraph.stratificationGroupC;
export const selectStratificationValue1 = (state) =>
  state.causalGraph.stratificationValue1;
export const selectStratificationValue2 = (state) =>
  state.causalGraph.stratificationValue2;
export const selectStratifications = (state) =>
  state.causalGraph.stratifications;
export const selectStratificationLabels = (state) =>
  state.causalGraph.stratificationLabels;
export const selectStratDistributionData = (state) =>
  state.causalGraph.stratDistributionData;
export const selectStratificationSubgroup = (state) =>
  state.causalGraph.stratificationSubgroup;
export const selectStratificationChartData = (state) =>
  state.causalGraph.stratificationChartData;

export default causalGraphSlice.reducer;
