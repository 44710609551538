import { APIStatus } from "../../../domain/_shared/type/status";
import {
  Group,
  DeleteGroupRequest,
  DeleteGroupInputDTO,
} from "../../../domain/data-manager/type/group";

export class GroupAdapter {
  static toDeleteGroupInputDTO(
    request: DeleteGroupRequest
  ): DeleteGroupInputDTO {
    const input_dto: DeleteGroupInputDTO = {
      category_id: request.id,
    };
    return input_dto;
  }

  static toGroupOutputDTO(response: any): Group[] {
    const output_dto: Group[] = response.subject_group
      ? response.subject_group.map((e: any) => ({
          id: e.id,
          name: e.name,
          description: e.description,
        }))
      : [];
    return output_dto;
  }

  static toGroupStatus(response: any): APIStatus {
    const output_dto: APIStatus = {
      status: response.status,
      data: response.data,
    };
    return output_dto;
  }
}
